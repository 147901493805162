import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import About from '../../components/about'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/properties_bag_logo.png'
import abimg from '../../images/about.jpg'
import About2 from "../../components/about2";
import ServiceSection2 from "../../components/Services2";
import AboutPageTitle from "../../components/pagetitle/about";


const About2Page =() => {
    return(
        <Fragment>
            <Navbar Logo={Logo}/>
            <AboutPageTitle pageTitle={'About Us'} pagesub={'About'}/>
            <About2 abimg={abimg}/>
            {/*<ProjectSection/>*/}
            <ServiceSection2/>
            {/*<FunFact fnClass={'wpo-fun-fact-section-s2'}/>*/}
            {/*<Pricing/>*/}
            {/*<TeamSection/>*/}
            {/*<Testimonial/>*/}
            <Footer ftClass={'wpo-site-footer-s2'}/>
            <Scrollbar/>
        </Fragment>
    )
};
export default About2Page;
