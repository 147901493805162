import axios from "axios";

export default class Api {
    constructor() {
      this.api_token = null;
      this.client = null;
      this.clientForRefresh = null;
      this.api_url = process.env.REACT_APP_API_ENDPOINT;
    }

    getLocalAccessToken() {
        const accessToken = window.localStorage.getItem("accessToken");
        return accessToken;

    }
     getLocalRefreshToken() {
        const refreshToken = window.localStorage.getItem("refreshToken");
        return refreshToken;

    }

    refreshToken() {

        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json',
        }

        this.clientForRefresh = axios.create({baseURL: this.api_url, timeout: 31000, headers: headers});



        return this.clientForRefresh.post("/_auth/refresh-token", {
            refreshToken: this.getLocalRefreshToken(),
        });
    }


    init = (headerParams) => {
      this.api_token = localStorage.getItem("accessToken");
      let headers = {
        Accept: "application/json"
      };

      if(headerParams) {
        headers = {...headers, ...headerParams}
      }

      if (this.api_token) {
        headers.Authorization = `Bearer ${
          this.api_token
        }`;
      }
      
      this.client = axios.create({baseURL: this.api_url, timeout: 31000, headers: headers});

    this.client.interceptors.response.use(
        (res) => {
            return res;
        },
        async (err) => {
            const originalConfig = err.config;

            if (err.response) {
                // Access Token was expired
                if (err.response.data.status === 401 && !originalConfig._retry) {
                    originalConfig._retry = true;

                    try {
                        const rs = await this.refreshToken();
                        const accessToken = rs.data.data.accessToken.token;

                        if(accessToken !== undefined){
                            localStorage.setItem("accessToken", accessToken);
                        }

                        return this.client(originalConfig);
                    } catch (_error) {
                        if (_error.response && _error.response.data) {
                            return Promise.reject(_error.response.data);
                        }

                        return Promise.reject(_error);
                    }
                }

                if (err.response.data.status === 403 && err.response.data) {
                    return Promise.reject(err.response.data);
                }
            }

            return Promise.reject(err);
        }
    );

      return this.client;
    };

    /*
    * API calls for BOOKINGS
    * */

    // Post new booking form
    createNewBooking = (params) => {
        return this.init().post("/bookings", params);
    }

    // Get all bookings
    getAllBookings = (params) => {
        return this.init().get("/bookings");
    }

    // Update booking Status
    updateBookingStatus = (id, params) => {
        return this.init().patch(`/bookings/${id}/status`, null, {params: params});
    }

    // Update booking document
    updateBookingDocument = (id, params) => {
        return this.init().patch(`/bookings/${id}/document`, null, {params: params});
    }

    // Get Booking by ID
    getBookingById = (id) => {
        return this.init().get(`/bookings/${id}`);
    }

    // Get Booking by Form Number
    getBookingByFormNumber = (formNumber) => {
        return this.init().get(`/bookings/form-number/${formNumber}`);
    }

    /*
    * API calls for USERS
    * */

    // Login user
    loginUser = (params) => {
        return this.init().post("/users/login", params);
    }

    // Register user
    registerUser = (params) => {
        return this.init().put("/users/register", params);
    }

    // Update user
    updateUser = (id, params) => {
        return this.init().patch(`/users/${id}/profile`, params);
    }

    // Approve/Disapprove user
    approveUser = (id, params) => {
        return this.init().patch(`/users/${id}/approve`, null, {params: params});
    }

    // Delete user
    deleteUser = (id) => {
        return this.init().delete(`/users/id/${id}`);
    }


    // Get all users
    getAllUsers = (params) => {
        return this.init().get("/users");
    }

    // Get all managers
    getAllManagers = (params) => {
        return this.init().get("/users/managers");
    }

    // Get all manager designations
    getAllManagerDesignations = () => {
        return this.init().get("/data/designations");
    }

    // Get all user roles
    getAllUserRoles = () => {
        return this.init().get("/data/roles");
    }

    // Get user by id
    getUserById = (id) => {
        return this.init().get(`/users/id/${id}`);
    }

    // Get all bookings of user
    getAllBookingsOfUser = (id) => {
        return this.init().get(`/users/${id}/bookings`);
    }

    /*
    * API calls for IMAGE UPLOAD
    * */

    // Upload image
    uploadImage = (params) => {
        return this.init({"Content-Type": "multipart/form-data"}).post("/file/upload", params);
    }

    /*
    * API calls for PRICING
     */
    getAllProjectPricing = () => {
        return this.init().get("/pricing");
    }



}