import * as React from 'react';
import {useRef, useState} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import Moment from 'moment';

import {
    Chip,
    Divider,
    FormControl,
    FormHelperText,
    Input,
    InputLabel,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import Api from "../../helpers/api";
import Sidebar from "../common/Sidebar";
import Copyright from "../common/Copyright";
import jwt_decode from "jwt-decode";
import {useParams} from "react-router-dom";
import {useReactToPrint} from "react-to-print";
import {LocalPhone, MailOutline} from "@mui/icons-material";

const theme = createTheme();

const bucketName = process.env.REACT_APP_BUCKET_NAME;

<style type="text/css" media="print">{"\
  @page {\ size: landscape;\ }\
"}</style>

export const PrintBooking = React.forwardRef((props:{customBookingId: any}, ref) => {

    console.log("Print Booking Props", props);
    console.log("Print Booking Ref", ref);

    let {bookingId} = useParams();

    if(bookingId === undefined || bookingId === null) {
        bookingId = props.customBookingId;
    }

    const api = new Api();

    const jwtDecoded:any = localStorage.getItem("accessToken") !== null ? jwt_decode(localStorage.getItem(`accessToken`) as string) : null;

    const [bookingApplicationFormNumber, setBookingApplicationFormNumber] = React.useState('001150');
    const [bookingStatus, setBookingStatus] = React.useState('');
    const [bookingDate, setBookingDate] = React.useState<Date | null>(new Date());
    const [bookingDealCode, setBookingDealCode] = React.useState('');
    const [bookingProjectName, setBookingProjectName] = React.useState('');

    // Customer Details
    const [applicantFullName, setApplicantFullName] = React.useState('');
    const [applicantCnic, setApplicantCnic] = React.useState('');

    const [applicantDob, setApplicantDob] = React.useState<Date | null>(new Date());
    const [applicantEmail, setApplicantEmail] = React.useState('');
    const [applicantContactNumber, setApplicantContactNumber] = React.useState('');

    const [applicantMailingAddress, setApplicantMailingAddress] = React.useState('');

    const [applicantPermanentAddress, setApplicantPermanentAddress] = React.useState('');

    const [applicantImageUrl, setApplicantImageUrl] = React.useState('');


    // Customer - Next of Kin Details
    const [nextOfKinFullName, setNextOfKinFullName] = React.useState('');
    const [nextOfKinCnic, setNextOfKinCnic] = React.useState('');

    const [nextOfKinRelationship, setNextOfKinRelationship] = React.useState('');
    const [nextOfKinEmail, setNextOfKinEmail] = React.useState('');
    const [nextOfKinContactNumber, setNextOfKinContactNumber] = React.useState('');

    const [nextOfKinMailingAddress, setNextOfKinMailingAddress] = React.useState('');
    const [nextOfKinPermanentAddress, setNextOfKinPermanentAddress] = React.useState('');


    // Booking Details
    const [bookingCategory, setBookingCategory] = React.useState('');
    const [bookingSize, setBookingSize] = React.useState('');

    const [realtorCompanyName, setRealtorCompanyName] = React.useState('');
    const [realtorContactNumber, setRealtorContactNumber] = React.useState('');
    const [realtorRepresentativeName, setRealtorRepresentativeName] = React.useState('');
    const [realtorRepresentativeCnic, setRealtorRepresentativeCnic] = React.useState('');


    // Payment Details
    const [bookingModeOfPayment, setBookingModeOfPayment] = React.useState('');
    const [bookingPrice, setBookingPrice] = React.useState('');

    const [bookingBankName, setBookingBankName] = React.useState('');
    const [bookingBankBranchName, setBookingBankBranchName] = React.useState('');

    const [bookingDsDdPoNo, setBookingDsDdPoNo] = React.useState('');

    const handleBookingSizeChange = (event: SelectChangeEvent) => {
        const currentBookingSize = event.target.value as string;
        setBookingSize(currentBookingSize as string);

        if(currentBookingSize === '7 Marla') {
            setBookingPrice('7700000');
        }
        else if(currentBookingSize === '10 Marla') {
            setBookingPrice('10000000');
        }
        else if(currentBookingSize === '1 Kanal') {
            setBookingPrice('18000000');
        }
        else if(currentBookingSize === '2 Kanal') {
            setBookingPrice('32000000');
        }

    }


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let applicant = {
            fullName: applicantFullName,
            cnic: applicantCnic,
            dateOfBirth: applicantDob,
            email: applicantEmail,
            phone: applicantContactNumber,
            mailingAddress: applicantMailingAddress,
            permanentAddress: applicantPermanentAddress
        };

        let nextOfKin = {
            fullName: nextOfKinFullName,
            cnic: nextOfKinCnic,
            relationship: nextOfKinRelationship,
            email: nextOfKinEmail,
            phone: nextOfKinContactNumber,
            mailingAddress: nextOfKinMailingAddress,
            permanentAddress: nextOfKinPermanentAddress
        };

        let booking = {
            projectName: bookingProjectName,
            formNumber: bookingApplicationFormNumber,
            bookingDate: bookingDate,
            dealCode: bookingDealCode,
            category: bookingCategory,
            size: bookingSize,
            bookingPrice: bookingPrice,
            modeOfPayment: bookingModeOfPayment,
            bankName: bookingBankName,
            bankBranch: bookingBankBranchName,
            paymentIdentifier: bookingDsDdPoNo,
/*            realtorCompany: realtorCompanyName,
            realtorPhone: realtorContactNumber,
            realtorRepresentative: realtorRepresentativeName,
            realtorCnic: realtorRepresentativeCnic,*/
            realtor: {
                id: jwtDecoded.uid
            },
            applicant: applicant,
            nextOfKin: nextOfKin
        }

        api.createNewBooking(booking).then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        });

    };

    // Use effect
    React.useEffect(() => {
        if(jwtDecoded) {
            api.getBookingById(bookingId)
                .then((response) => {
                    setBookingApplicationFormNumber(response.data.data.formNumber);
                    setBookingStatus(response.data.data.bookingStatus);
                    setBookingDate(response.data.data.bookingDate);
                    setBookingDealCode(response.data.data.dealCode);
                    setBookingProjectName(response.data.data.projectName);

                    setApplicantFullName(response.data.data.applicant.fullName);
                    setApplicantCnic(response.data.data.applicant.cnic.number);
                    setApplicantDob(response.data.data.applicant.dateOfBirth);
                    setApplicantEmail(response.data.data.applicant.email);
                    setApplicantContactNumber(response.data.data.applicant.phone);
                    setApplicantMailingAddress(response.data.data.applicant.mailingAddress);
                    setApplicantPermanentAddress(response.data.data.applicant.permanentAddress);
                    setApplicantImageUrl(`https://${bucketName}.s3.amazonaws.com/${response.data.data.applicant.image}`);

                    setNextOfKinFullName(response.data.data.nextOfKin.fullName);
                    setNextOfKinCnic(response.data.data.nextOfKin.cnic.number);
                    setNextOfKinRelationship(response.data.data.nextOfKin.relationship);
                    setNextOfKinEmail(response.data.data.nextOfKin.email);
                    setNextOfKinContactNumber(response.data.data.nextOfKin.phone);
                    setNextOfKinMailingAddress(response.data.data.nextOfKin.mailingAddress);
                    setNextOfKinPermanentAddress(response.data.data.nextOfKin.permanentAddress);


                    setBookingCategory(response.data.data.category);
                    setBookingSize(response.data.data.size);

                    setRealtorCompanyName(response.data.data.realtor.companyName);
                    setRealtorRepresentativeName(response.data.data.realtor.firstName + ' ' + response.data.data.realtor.lastName);
                    setRealtorRepresentativeCnic(response.data.data.realtor.cnic.number);
                    setRealtorContactNumber(response.data.data.realtor.phoneNumbers[0]);

                    setBookingPrice(response.data.data.bookingPrice);
                    setBookingModeOfPayment(response.data.data.modeOfPayment);
                    setBookingBankName(response.data.data.bankName);
                    setBookingBankBranchName(response.data.data.bankBranch);
                    setBookingDsDdPoNo(response.data.data.paymentIdentifier);



                }).catch((error) => {
                console.log(error);
            });

        }

    }, []);

    const [selectedApplicantImage, setSelectedApplicantImage] = React.useState<File | null>(null);
    const [selectedApplicantImageName, setSelectedApplicantImageName] = React.useState<string | null>(null);

    const [applicantCnicImage, setApplicantCnicImage] = React.useState();
    const [nextOfKinCnicImage, setNextOfKinCnicImage] = React.useState();
    const [poImage, setPoImage] =  React.useState();

    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#0960e6");
    const onStatusChange = (id: any, status: any) => {

        api.updateBookingStatus(id, {bookingStatus: status}).then((response) => {
                console.log(response);
                // updateBookingData(response.data.data);
            }
        ).catch((error) => {
                console.log(error);
            }
        )

    }

/*    const componentRef = useRef<HTMLInputElement>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });*/

/*    const componentRef = useRef<HTMLInputElement>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });*/


    // @ts-ignore
    const bookingForm = (

     <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Box ref={ref}
                 sx={{
                     paddingX: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >

                <Grid container textAlign={"center"} alignItems={"center"} mt={15} mb={3}>

                        <Grid item xs={12} sm={12}>
                            <Typography variant={"h4"} style={{fontWeight: 'bold'}} >
                                Application Form
                            </Typography>
                        </Grid>
                </Grid>

                <Box component="form" aria-disabled={true} noValidate onSubmit={handleSubmit} sx={{ mt: 0.5 }}>
                    <Grid container spacing={2}>

                        {/* Application Form Number */}
                        <Grid item xs={12} sm={8}>
                                {/*<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>*/}

                            <Grid container spacing={1}>

                                <Grid item xs={12} sm={3.8} mt={0.5}>
                                <InputLabel style={{color:"#000"}}>Application Form no:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={8.2}>

                                            <TextField
                                                fullWidth
                                        value={bookingApplicationFormNumber}
                                        id="input-with-sx" variant="standard" />

                                </Grid>

                            </Grid>
                                {/*</Box>*/}

                        </Grid>

                        {/* Date */}
                        <Grid item xs={12} sm={4}>
                            {/*<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>*/}

                            <Grid container spacing={1}>

                                <Grid item xs={12} sm={3.5} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Date:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={8.5}>

                                    <TextField
                                        fullWidth
                                        value={Moment(bookingDate).format('DD/MM/YYYY')}
                                        id="input-with-sx" variant="standard" />
                                </Grid>

                            </Grid>


                            {/*</Box>*/}

                        </Grid>

                        {/* Project Name */}
                        <Grid item xs={12} sm={8}>
                            {/*<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>*/}
                            <Grid container spacing={1}>

                                <Grid item xs={12} sm={2.8} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Project Name:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9.2}>

                                    <TextField
                                        fullWidth
                                        value={bookingProjectName}
                                        id="input-with-sx" variant="standard" />
                                </Grid>

                            </Grid>


                            {/*</Box>*/}
                        </Grid>

                        {/* Deal Code */}
                        <Grid item xs={12} sm={4}>
                            {/*<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>*/}
                                <Grid container spacing={1}>

                                    <Grid item xs={12} sm={4.4} mt={0.5}>
                                        <InputLabel style={{color:"#000"}}>Deal Code:</InputLabel>
                                    </Grid>
                                    <Grid item xs={12} sm={7.6}>

                                        <TextField
                                            fullWidth
                                            value={bookingDealCode}
                                            id="input-with-sx" variant="standard" />
                                    </Grid>

                                </Grid>


                            {/*</Box>*/}
                        </Grid>




                        {/* Customer Full Name  */}
                        <Grid item xs={12} sm={8}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3.3} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Customer Name:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={8.7}>
                                    <TextField
                                        fullWidth
                                        value={applicantFullName}
                                         variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Customer CNIC  */}
                        <Grid item xs={12} sm={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>CNIC:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <TextField
                                        fullWidth
                                        value={applicantCnic}
                                        id="input-with-sx" variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>






                        {/* Customer Date of Birth */}
                        <Grid item xs={12} sm={4}>
                            {/*<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>*/}

                            <Grid container spacing={1}>

                                <Grid item xs={12} sm={6} mt={0.5}>
                                {/*<Grid item xs={'auto'} mt={0.5}>*/}
                                    <InputLabel style={{color:"#000"}}>Date of Birth:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                {/*<Grid item xs={'auto'}>*/}

                                    <TextField
                                        fullWidth
                                        value={Moment(applicantDob).format('DD/MM/YYYY')
                                        } variant="standard" />
                                </Grid>

                            </Grid>


                            {/*</Box>*/}

                        </Grid>


                        {/* Customer Email  */}
                        <Grid item xs={12} md={4}>
                            {/*<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>*/}

                            <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>

                                <Grid item xs={12} sm={2.5} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Email:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9.5} wrap={'wrap'}>
                                    <TextField
                                        fullWidth
                                        value={applicantEmail}
                                        id="input-with-sx" variant="standard" />
                                </Grid>

                            </Grid>
                            {/*</Box>*/}

                        </Grid>

                        {/* Customer Cell Number  */}
                        <Grid item xs={12} sm={4}>
                            <Grid container spacing={1}>

                                <Grid item xs={12} sm={3.3} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Cell no:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={8.7}>

                                    <TextField
                                        fullWidth
                                        value={applicantContactNumber}
                                        id="input-with-sx" variant="standard" />
                                </Grid>

                            </Grid>
                        </Grid>




                        {/* Customer Mailing Address  */}
                        <Grid item xs={12} sm={12}>
                            <Grid container spacing={1}>

                                <Grid item xs={12} sm={2.2} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Mailing Address:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9.8}>

                                    <TextField
                                        fullWidth
                                        value={applicantMailingAddress} variant="standard" />
                                </Grid>

                            </Grid>
                        </Grid>

                        {/* Customer Permanent Address  */}
                        <Grid item xs={12} sm={12}>
                            <Grid container spacing={1}>

                                <Grid item xs={12} sm={2.6} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Permanent Address:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9.4}>

                                    <TextField
                                        fullWidth
                                        value={applicantPermanentAddress} variant="standard" />
                                </Grid>

                            </Grid>
                        </Grid>

                        {/*<Divider variant={"middle"} flexItem />*/}








                        {/* Next of kin declaration  */}
                        <Grid item xs={12} textAlign={"center"} alignItems={"center"} >
                            {/*<Chip label="I hereby nominate the following as my next of kin (attach copy)" color="primary" />*/}
                            <Typography variant="subtitle1" textAlign={"center"} alignItems={"center"} >
                                I hereby nominate the following as my next of kin (attach copy)
                            </Typography>
                        </Grid>






                        {/* Next of Kin Full Name  */}
                        <Grid item xs={12} sm={8}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={2.2} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Full Name:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9.8}>
                                    <TextField
                                        fullWidth
                                        value={nextOfKinFullName}
                                         variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Next of Kin CNIC  */}
                        <Grid item xs={12} sm={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>CNIC:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <TextField
                                        fullWidth
                                        value={nextOfKinCnic}
                                        id="input-with-sx" variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>






                        {/* Next of Kin Relationship */}
                        <Grid item xs={12} sm={3.5}>
                            {/*<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>*/}

                            <Grid container spacing={1}>

                                <Grid item xs={12} sm={6.2} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Relationship:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={5.8}>

                                    <TextField
                                        fullWidth
                                        value={nextOfKinRelationship}
                                        id="input-with-sx" variant="standard" />
                                </Grid>

                            </Grid>


                            {/*</Box>*/}

                        </Grid>


                        {/* Next of Kin Email  */}
                        <Grid item xs={12} sm={4.5}>
                            {/*<Box sx={{ display: 'flex', alignItems: 'flex-end' }}>*/}

                            <Grid container spacing={1}>

                                <Grid item xs={12} sm={2.5} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Email:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9.5}>
                                    <TextField
                                        fullWidth
                                        value={nextOfKinEmail}
                                        id="input-with-sx" variant="standard" />

                                </Grid>

                            </Grid>
                            {/*</Box>*/}

                        </Grid>

                        {/* Next of Kin Cell Number  */}
                        <Grid item xs={12} sm={4}>
                            <Grid container spacing={1}>

                                <Grid item xs={12} sm={3.3} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Cell no:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={8.7}>
                                    <TextField
                                        fullWidth
                                        value={nextOfKinContactNumber}
                                        id="input-with-sx" variant="standard" />
                                </Grid>

                            </Grid>
                        </Grid>




                        {/* Next of Kin Mailing Address  */}
                        <Grid item xs={12} sm={12}>
                            <Grid container spacing={1}>

                                <Grid item xs={12} sm={2.2} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Mailing Address:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9.8}>

                                    <TextField
                                        fullWidth
                                        value={nextOfKinMailingAddress} variant="standard" />
                                </Grid>

                            </Grid>
                        </Grid>

                        {/* Next of Kin Permanent Address  */}
                        <Grid item xs={12} sm={12}>
                            <Grid container spacing={1}>

                                <Grid item xs={12} sm={2.6} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Permanent Address:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9.4}>

                                    <TextField
                                        fullWidth
                                        value={nextOfKinPermanentAddress} variant="standard" />
                                </Grid>

                            </Grid>
                        </Grid>




                        {/* Empty Space  */}
{/*
                        <Grid mt={1} item xs={12}>
                        </Grid>
*/}




                        {/* Category */}
                        <Grid item xs={12} sm={8}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={2} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Category:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <TextField
                                        fullWidth
                                        value={bookingCategory}
                                        id="input-with-sx" variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Size */}
                        <Grid item xs={12} sm={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Size:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <TextField
                                        fullWidth
                                        value={bookingSize}
                                        id="input-with-sx" variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>






                        {/* Company Name  */}
                        <Grid item xs={12} sm={8}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3.3} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Company name:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={8.7}>
                                    <TextField
                                        fullWidth
                                        value={realtorCompanyName}
                                        id="input-with-sx" variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Realtor Contact Number  */}
                        <Grid item xs={12} sm={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3.3} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Cell no:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={8.7}>
                                    <TextField
                                        fullWidth
                                        value={realtorContactNumber}
                                        id="input-with-sx" variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Rep Name  */}
                        <Grid item xs={12} sm={8}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={2.2} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Rep name:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9.8}>
                                    <TextField
                                        fullWidth
                                        value={realtorRepresentativeName}
                                        id="input-with-sx" variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Realtor CNIC  */}
                        <Grid item xs={12} sm={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>CNIC:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <TextField
                                        fullWidth
                                        value={realtorRepresentativeCnic}
                                        id="input-with-sx" variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Empty Space  */}
                        <Grid mt={1} item xs={12}>
                        </Grid>


                        {/* Mode of Payment */}
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={4.8} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Mode of Payment:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={7.2}>
                                    <TextField
                                        fullWidth
                                        value={bookingModeOfPayment}
                                        id="input-with-sx" variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Booking Price  */}
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3.8} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Booking price:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={8.2}>
                                    <TextField
                                        fullWidth
                                        value={bookingPrice}
                                        id="input-with-sx" variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>

                        {bookingModeOfPayment === "Sales Partner Account" ?
                            <Grid mt={1} item xs={12}>
                                <Typography align={"center"}>
                                    Note: This Booking is made through Sales Partner’s account. Customer must take receiving from
                                    Sales Partner who is booking the plot.
                                </Typography>
                            </Grid>
                            :
                            <>

                                {/* Bank Name  */}
                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={3.2} mt={0.5}>
                                            <InputLabel style={{color:"#000"}}>Bank name:</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} sm={8.8}>
                                            <TextField
                                                fullWidth
                                                value={bookingBankName}
                                                id="input-with-sx" variant="standard" />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* Branch Name  */}
                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={3.7} mt={0.5}>
                                            <InputLabel style={{color:"#000"}}>Branch name:</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} sm={8.3}>
                                            <TextField
                                                fullWidth
                                                value={bookingBankBranchName}
                                                id="input-with-sx" variant="standard" />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                {/* D.Slip, DD, PO No.  */}
                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={4.6} mt={0.5}>
                                            <InputLabel style={{color:"#000"}}>D.Slip/DD/PO no:</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} sm={7.4}>
                                            <TextField
                                                fullWidth
                                                value={bookingDsDdPoNo}
                                                id="input-with-sx" variant="standard" />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </>
                        }

                        {/* Empty Space  */}
                        <Grid mt={0} item xs={12}>
                        </Grid>

                        {/* Booking Rep  */}
                        <Grid item xs={12} sm={6} textAlign={"center"}>
                            <FormControl variant="standard">
                                <Input
                                    id="bookingRealtor"
                                    aria-describedby="bookingRealtor-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                                <FormHelperText id="bookingRealtor-helper-text" style={{fontSize:"medium", textAlign:"center"}}>Booking Rep</FormHelperText>
                            </FormControl>
                        </Grid>

                        {/* Applicant Signature  */}
                        <Grid item xs={12} sm={6} textAlign={"center"}>
                            <FormControl variant="standard">
                                <Input
                                    id="applicantSignature"
                                    aria-describedby="applicantSignature-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                                <FormHelperText id="applicantSignature-helper-text" style={{fontSize:"medium", textAlign:"center"}}>Applicant Signature</FormHelperText>
                            </FormControl>
                        </Grid>

                        {/* Empty Space  */}
                        <Grid mt={2} item xs={12}>
                        </Grid>

                    </Grid>
                </Box>

                {/*<Copyright />*/}

            </Box>
        </Container>
    </ThemeProvider>

    );




  return <Sidebar childComponent={bookingForm} index={0}  title={"New Booking"} />;
});