import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import Scrollbar from '../../components/scrollbar'
import {useParams} from 'react-router-dom'
import Services from '../../api/service'
import Logo from '../../images/properties_bag_logo.png'
import Footer from '../../components/footer';
import sales from '../../images/service-single/sales-single.jpg'


const ServiceSalesSinglePage = (props) => {
    const { name } = useParams()

    const serviceDetails = Services.find(item => item.name === name)

    return (
        <Fragment>
            <Navbar Logo={Logo}/>
            <PageTitle pageTitle={"Sales"} pagesub={'Services'} />
            <div className="wpo-service-single-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-12">
                            <div className="wpo-service-single-wrap">
                                <div className="wpo-service-single-item">
                                    <div className="wpo-service-single-main-img">
                                        <img src={sales} alt=""/>
                                    </div>
                                    <div className="wpo-service-single-title">
                                        <h3>Sales</h3>
                                    </div>
                                    <p>Propertiesbag.com is a contemporary sales portal, purpose-built for the booking and sales of real estate projects. We are providing the direly needed automation in the real estate market, to promote accountability and transparency, so the public can invest in the market without any hassle or concern for the security of their investment. We also provide security in the sense of vetting the projects associated with our platform, by thoroughly ensuring their legitimacy and legality.</p>

                                    <div className="row mt-4">
                                        <div className="col-md-6 col-sm-6 col-6">
                                            <div className="wpo-p-details-img">
                                                <img src="assets/images/service-single/2.jpg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-6">
                                            <div className="wpo-p-details-img">
                                                <img src="assets/images/service-single/3.jpg" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="wpo-service-single-item list-widget">
                                    <div className="wpo-service-single-title">
                                        <h3>Our Capabilities</h3>
                                    </div>
                                    <p>Our sales module holds the following characteristics:</p>
                                    <ul>
                                        <li>Pre-booking of plot files.</li>
                                        <li>Progressive confirmation status of your financial transactions until the booking is confirmed.</li>
                                        <li>Real time confirmation of all payments and installments made over time.</li>
                                        <li>Security of your booked assets.</li>
                                        <li>A dedicated sales team for your guidance.</li>
                                    </ul>
                                </div>
                                <div className="wpo-service-single-item">
                                    <div className="wpo-service-single-title">
                                        <h3>Our approach</h3>
                                    </div>
                                    <p>Propertiesbag.com operates in the capacity of the sales and marketing partner to the real estate projects enlisted on our portal, where all the projects are thoroughly vetted for legitimacy and legality. We have our dedicated sales team, and external contractors termed as “Authorized Sales Partners”, for your thorough guidance on the projects. If you wish to proceed with the booking of any commodity, our booking agents will register your application on our portal. Upon provision of the required proof of financial transaction, you will be provided with a booking receipt with a distinct barcode. You can scan that barcode to affirm the status of all the transactions you will be making against that booking, hence ensuring the security of your investment, and that it is making it to the desired destination.</p>
                                </div>
                                <div className="wpo-service-single-item list-widget">
                                    <div className="wpo-service-single-title">
                                        <h3>Our Work Process</h3>
                                    </div>
                                    <ul>
                                        <li>Extreme vetting of the projects on our panel.</li>
                                        <li>Engage our sales team and authorized sales partners to provide proper project related guidance to the public.</li>
                                        <li>Provide a booking platform and means to do so, for hassle free investment.</li>
                                        <li>Provide transparency for all your financial transactions, in the most simple and accessible way possible.</li>
                                    </ul>
                                </div>
                                {/*<RelatedService/>*/}
                                {/* <Discuss/>*/}
                            </div>
                        </div>
                        {/*<ServiceSidebar/>*/}
                    </div>
                </div>
            </div>
            <Footer ftClass={'wpo-site-footer-s2'}/>
            <Scrollbar />
        </Fragment>
    )
};
export default ServiceSalesSinglePage;
