import * as React from 'react';
import {ChangeEvent, useState} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import MaskedInput from 'react-text-mask'
import {
    Chip,
    Divider,
    FormControl,
    FormHelperText,
    Input,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent, Step, StepButton, StepContent, StepLabel,
    Stepper,
    Table,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import Api from "../../helpers/api";
import Sidebar from "../common/Sidebar";
import Copyright from "../common/Copyright";
import jwt_decode from "jwt-decode";
import {BounceLoader} from "react-spinners";
import {PhotoCamera} from "@mui/icons-material";
import {LoadingButton} from "@mui/lab";
import ReactInputMask from "react-input-mask";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import BookingFormStep1 from "./new/BookingFormStep1";
import BookingFormStep2 from "./new/BookingFormStep2";
import Html5QrcodePlugin from "../Html5QrcodePlugin";

const theme = createTheme();

const bucketName = process.env.REACT_APP_BUCKET_NAME;
export default function BarcodeScanner() {

    const api = new Api();

    const jwtDecoded:any = localStorage.getItem("accessToken") !== null ? jwt_decode(localStorage.getItem(`accessToken`) as string) : null;


    const [bookingApplicationFormNumber, setBookingApplicationFormNumber] = React.useState('001150');
    const [bookingDate, setBookingDate] = React.useState<Date | null>(new Date());
    const [bookingDealCode, setBookingDealCode] = React.useState('');

    // Customer Details
    const [applicantFullName, setApplicantFullName] = React.useState('');
    const [applicantCnic, setApplicantCnic] = React.useState('');

    const [applicantDob, setApplicantDob] = React.useState<Date | null>(new Date());
    const [applicantEmail, setApplicantEmail] = React.useState('');
    const [applicantContactNumber, setApplicantContactNumber] = React.useState('');

    const [applicantMailingAddress, setApplicantMailingAddress] = React.useState('');

    const [applicantPermanentAddress, setApplicantPermanentAddress] = React.useState('');

    // Customer - Next of Kin Details
    const [nextOfKinFullName, setNextOfKinFullName] = React.useState('');
    const [nextOfKinCnic, setNextOfKinCnic] = React.useState('');

    const [nextOfKinRelationship, setNextOfKinRelationship] = React.useState('');
    const [nextOfKinEmail, setNextOfKinEmail] = React.useState('');
    const [nextOfKinContactNumber, setNextOfKinContactNumber] = React.useState('');

    const [nextOfKinMailingAddress, setNextOfKinMailingAddress] = React.useState('');
    const [nextOfKinPermanentAddress, setNextOfKinPermanentAddress] = React.useState('');


    // Booking Details
    const [bookingCategory, setBookingCategory] = React.useState('');
    const [bookingSize, setBookingSize] = React.useState('');

    const [realtorCompanyName, setRealtorCompanyName] = React.useState('');
    const [realtorContactNumber, setRealtorContactNumber] = React.useState('');
    const [realtorRepresentativeName, setRealtorRepresentativeName] = React.useState('');
    const [realtorRepresentativeCnic, setRealtorRepresentativeCnic] = React.useState('');


    // Payment Details
    const [bookingModeOfPayment, setBookingModeOfPayment] = React.useState('');
    const [bookingPrice, setBookingPrice] = React.useState('');

    const [bookingBankName, setBookingBankName] = React.useState('');
    const [bookingBankBranchName, setBookingBankBranchName] = React.useState('');

    const [bookingDsDdPoNo, setBookingDsDdPoNo] = React.useState('');

    const handleBookingSizeChange = (event: SelectChangeEvent) => {
        const currentBookingSize = event.target.value as string;
        setBookingSize(currentBookingSize as string);

        if(currentBookingSize === '7 Marla') {
            setBookingPrice('7700000');
        }
        else if(currentBookingSize === '10 Marla') {
            setBookingPrice('10000000');
        }
        else if(currentBookingSize === '1 Kanal') {
            setBookingPrice('18000000');
        }
        else if(currentBookingSize === '2 Kanal') {
            setBookingPrice('32000000');
        }

    }


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let applicant = {
            fullName: applicantFullName,
            cnic: applicantCnic,
            dateOfBirth: applicantDob,
            email: applicantEmail,
            phone: applicantContactNumber,
            mailingAddress: applicantMailingAddress,
            permanentAddress: applicantPermanentAddress
        };

        let nextOfKin = {
            fullName: nextOfKinFullName,
            cnic: nextOfKinCnic,
            relationship: nextOfKinRelationship,
            email: nextOfKinEmail,
            phone: nextOfKinContactNumber,
            mailingAddress: nextOfKinMailingAddress,
            permanentAddress: nextOfKinPermanentAddress
        };

        let booking = {
            projectName: "Affinity Enclave",
            formNumber: bookingApplicationFormNumber,
            bookingDate: bookingDate,
            dealCode: bookingDealCode,
            category: bookingCategory,
            size: bookingSize,
            bookingPrice: bookingPrice,
            modeOfPayment: bookingModeOfPayment,
            bankName: bookingBankName,
            bankBranch: bookingBankBranchName,
            paymentIdentifier: bookingDsDdPoNo,
/*            realtorCompany: realtorCompanyName,
            realtorPhone: realtorContactNumber,
            realtorRepresentative: realtorRepresentativeName,
            realtorCnic: realtorRepresentativeCnic,*/
            realtor: {
                id: jwtDecoded.uid
            },
            applicant: applicant,
            nextOfKin: nextOfKin
        }

        api.createNewBooking(booking).then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        });

    };

    // Use effect
    React.useEffect(() => {
        if(jwtDecoded) {
            api.getUserById(jwtDecoded.uid)
                .then((response) => {
                    setRealtorCompanyName(response.data.data.companyName);
                    setRealtorRepresentativeName(response.data.data.firstName + ' ' + response.data.data.lastName);
                    setRealtorRepresentativeCnic(response.data.data.cnic.number);
                    setRealtorContactNumber(response.data.data.phoneNumbers[0]);
                }).catch((error) => {
                console.log(error);
            });
        }
    }, []);

    const handleFileUploadError = (error:any) => {
        // Do something...
    }

    const handleFilesChange = (files:any) => {
        // Do something...
    }

    const [applicantImageUrl, setApplicantImageUrl] = React.useState('');
    const [selectedApplicantImage, setSelectedApplicantImage] = React.useState<File | null>(null);
    const [selectedApplicantImageName, setSelectedApplicantImageName] = React.useState<string | null>(null);


    const [applicantCnicImage, setApplicantCnicImage] = React.useState();
    const [nextOfKinCnicImage, setNextOfKinCnicImage] = React.useState();
    const [poImage, setPoImage] =  React.useState();

    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#0960e6");

    const [testFormNumber, setTestFormNumber] = React.useState('');
    const [testBooking, setTestBooking] = React.useState(null);

    const handleApplicantImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return
        setSelectedApplicantImage(event.target.files[0]);
        setSelectedApplicantImageName(event.target.files[0].name);

        uploadImage();
    }

    const uploadImage = () => {

        setLoading(true);

        const params = {
            file: selectedApplicantImage,
            fileName: selectedApplicantImageName,
        }

        api.uploadImage(params).then((response) => {
                console.log(response);
            setApplicantImageUrl(`https://${bucketName}.s3.amazonaws.com/${params.fileName}`);
            setLoading(false);
            selectedApplicantImage && setSelectedApplicantImage(null);
            }
        ).catch((error) => {
                console.log(error);
            setLoading(false);
            }
        );
    }



    // const steps = ['Select campaign settings', 'Create an ad group', 'Create an ad'];


    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState<{
        [k: number]: boolean;
    }>({});

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = (booking: any) => {

        if(booking) {
            // setTestFormNumber(booking.formNumber);
            setTestBooking(booking);
        }

        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                  // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step: number) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext(null);
    };

    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };


    const steps = [
        {
            label: 'Fill out booking details',
            content: <BookingFormStep1 handleNext={handleNext} />,

        },
        {
            label: 'Upload documents',
            content: <BookingFormStep2 booking={testBooking} />,

        },
        {
            label: 'Create an ad',
            content: `Try out different ad text to see what brings in the most customers,
              and learn how to enhance your ads using features like ad extensions.
              If you run into any problems with your ads, find out how to tell if
              they're running and how to resolve approval issues.`,
        },
    ];


    const bookingForm = (

     <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
            <CssBaseline />

            <Stepper nonLinear activeStep={activeStep}>
                {steps.map((step, index) => (
                    <Step key={step.label} completed={completed[index]}>
                        <StepButton color="inherit"
                                    // onClick={handleStep(index)}
                        >
                            {step.label}
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
            <div>
                {allStepsCompleted() ? (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1 }}>
                            All steps completed - you&apos;re finished
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button onClick={handleReset}>Reset</Button>
                        </Box>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <Typography sx={{ mt: 2, mb: 1, py: 1 }}>
                            {steps[activeStep].content}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
{/*                            <Button
                                color="inherit"
                                disabled={activeStep === 0}
                                onClick={handleBack}
                                sx={{ mr: 1 }}
                            >
                                Back
                            </Button>*/}
{/*                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button variant={'contained'} onClick={handleNext} sx={{ mr: 1 }}>
                                Next
                            </Button>*/}
{/*                            {activeStep !== steps.length &&
                                (completed[activeStep] ? (
                                    <Typography variant="caption" sx={{ display: 'inline-block' }}>
                                        Step {activeStep + 1} already completed
                                    </Typography>
                                ) : (
                                    <Button onClick={handleComplete}>
                                        {completedSteps() === totalSteps() - 1
                                            ? 'Finish'
                                            : 'Complete Step'}
                                    </Button>
                                ))}*/}
                        </Box>
                    </React.Fragment>
                )}
            </div>

            <Copyright sx={{ mt: 5 }} />
        </Container>
    </ThemeProvider>

    );

    const [barCodeText, setBarCodeText] = React.useState('');
    const [barCodeResult, setBarCodeResult] = React.useState('');

    const onNewScanResult = (decodedText:any, decodedResult:any) => {
        setBarCodeText(decodedText);
        // setBarCodeResult(decodedResult);
    }




  return (
      <>

          <Grid container textAlign={"center"} alignItems={"center"} spacing={2}>
              <Grid item xs={12} sm={12}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Bar Code Text: {barCodeText}
                    </Typography>
              </Grid>

          </Grid>



      <Html5QrcodePlugin
      fps={10}
      qrbox={{
          width: 600,
          height: 300
      }}
      disableFlip={false}
      qrCodeSuccessCallback={onNewScanResult}/>

      </>
          )
}