import * as React from 'react';
import {useEffect, useState} from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {Alert, FormControl} from "@mui/material";
import Api from "../../helpers/api";
import Copyright from "../common/Copyright";
import jwt_decode from "jwt-decode";
import {Html5Qrcode, Html5QrcodeScanType} from "html5-qrcode";
import {Html5QrcodeScannerState} from "html5-qrcode/esm/state-manager";
import Button from "@mui/material/Button";

const theme = createTheme();

const bucketName = process.env.REACT_APP_BUCKET_NAME;
const qrcodeRegionId = "html5qr-code-full-region";


export default function BarcodeVerifier() {

    

    const api = new Api();

    const jwtDecoded:any = localStorage.getItem("accessToken") !== null ? jwt_decode(localStorage.getItem(`accessToken`) as string) : null;

    // const [bookingId, setBookingId] = useState("");
    const [booking, setBooking] = useState<any>(null);
    const [bookingApplicationFormNumber, setBookingApplicationFormNumber] = React.useState('001150');
    const [bookingMembershipId, setBookingMembershipId] = React.useState('001150');
    const [bookingStatus, setBookingStatus] = React.useState('');
    const [bookingDate, setBookingDate] = React.useState<Date | null>(new Date());
    const [bookingDealCode, setBookingDealCode] = React.useState('');
    const [bookingProjectName, setBookingProjectName] = React.useState('');

    // Customer Details
    const [applicantFullName, setApplicantFullName] = React.useState('');
    const [applicantCnic, setApplicantCnic] = React.useState('');

    const [applicantDob, setApplicantDob] = React.useState<Date | null>(new Date());
    const [applicantEmail, setApplicantEmail] = React.useState('');
    const [applicantContactNumber, setApplicantContactNumber] = React.useState('');

    const [applicantMailingAddress, setApplicantMailingAddress] = React.useState('');

    const [applicantPermanentAddress, setApplicantPermanentAddress] = React.useState('');

    // Customer - Next of Kin Details
    const [nextOfKinFullName, setNextOfKinFullName] = React.useState('');
    const [nextOfKinCnic, setNextOfKinCnic] = React.useState('');

    const [nextOfKinRelationship, setNextOfKinRelationship] = React.useState('');
    const [nextOfKinEmail, setNextOfKinEmail] = React.useState('');
    const [nextOfKinContactNumber, setNextOfKinContactNumber] = React.useState('');

    const [nextOfKinMailingAddress, setNextOfKinMailingAddress] = React.useState('');
    const [nextOfKinPermanentAddress, setNextOfKinPermanentAddress] = React.useState('');


    // Booking Details
    const [bookingCategory, setBookingCategory] = React.useState('');
    const [bookingSize, setBookingSize] = React.useState('');

    const [realtorCompanyName, setRealtorCompanyName] = React.useState('');
    const [realtorContactNumber, setRealtorContactNumber] = React.useState('');
    const [realtorRepresentativeName, setRealtorRepresentativeName] = React.useState('');
    const [realtorRepresentativeCnic, setRealtorRepresentativeCnic] = React.useState('');


    // Payment Details
    const [bookingModeOfPayment, setBookingModeOfPayment] = React.useState('');
    const [bookingPrice, setBookingPrice] = React.useState('');

    const [bookingBankName, setBookingBankName] = React.useState('');
    const [bookingBankBranchName, setBookingBankBranchName] = React.useState('');

    const [bookingDsDdPoNo, setBookingDsDdPoNo] = React.useState('');

    const [html5Qrcode, setHtml5Qrcode] = React.useState<any>(null);
    const [scanError, setScanError] = React.useState<any>(null);


    const startCamera = () => {

        setScanError(null);
        setBooking(null);

        if (html5Qrcode) {
            html5Qrcode.start(
                {facingMode: "environment"},
                {
                    fps: 30,
                    qrbox: {
                        width: 600,
                        height: 300
                    },
                    scanType: Html5QrcodeScanType.SCAN_TYPE_CAMERA,
                    disableFlip: false
                },
                onScanSuccess,
                onScanFailure,
            ).then((ignore: any) => {
                    console.log("Camera started successfully.");
                    console.log("Camera State: ", html5Qrcode.getState());
                    console.log("Camera State: ", Html5QrcodeScannerState.SCANNING);

                    console.log("Type of camera state", typeof html5Qrcode?.getState());

                }
            ).catch(() => {
                    console.log("Camera failed to start.");
                }
            );
        }
    }

    const stopCamera = () => {
        if (html5Qrcode) {
            html5Qrcode.stop();
            setScanError(null);
        }
    }


    const onScanFailure = (errorMessage:any) => {
        console.log(errorMessage);
    }

    const onScanSuccess = (decodedText:any, decodedResult:any) => {

            api.getBookingByFormNumber(decodedText)
                .then((response) => {
                    setBooking(response.data.data);
                    setBookingApplicationFormNumber(response.data.data.formNumber);
                    setBookingMembershipId(response.data.data.memberId);
                    setBookingStatus(response.data.data.bookingStatus);
                    setBookingDate(response.data.data.bookingDate);
                    setBookingDealCode(response.data.data.dealCode);
                    setBookingProjectName(response.data.data.projectName);

                    setApplicantFullName(response.data.data.applicant.fullName);
                    setApplicantCnic(response.data.data.applicant.cnic.number);
                    setApplicantDob(response.data.data.applicant.dateOfBirth);
                    setApplicantEmail(response.data.data.applicant.email);
                    setApplicantContactNumber(response.data.data.applicant.phone);
                    setApplicantMailingAddress(response.data.data.applicant.mailingAddress);
                    setApplicantPermanentAddress(response.data.data.applicant.permanentAddress);

                    setNextOfKinFullName(response.data.data.nextOfKin.fullName);
                    setNextOfKinCnic(response.data.data.nextOfKin.cnic.number);
                    setNextOfKinRelationship(response.data.data.nextOfKin.relationship);
                    setNextOfKinEmail(response.data.data.nextOfKin.email);
                    setNextOfKinContactNumber(response.data.data.nextOfKin.phone);
                    setNextOfKinMailingAddress(response.data.data.nextOfKin.mailingAddress);
                    setNextOfKinPermanentAddress(response.data.data.nextOfKin.permanentAddress);


                    setBookingCategory(response.data.data.category);
                    setBookingSize(response.data.data.size);

                    setRealtorCompanyName(response.data.data.realtor.companyName);
                    setRealtorRepresentativeName(response.data.data.realtor.firstName + ' ' + response.data.data.realtor.lastName);
                    setRealtorRepresentativeCnic(response.data.data.realtor.cnic.number);
                    setRealtorContactNumber(response.data.data.realtor.phoneNumbers[0]);

                    setBookingPrice(response.data.data.bookingPrice);
                    setBookingModeOfPayment(response.data.data.modeOfPayment);
                    setBookingBankName(response.data.data.bankName);
                    setBookingBankBranchName(response.data.data.bankBranch);
                    setBookingDsDdPoNo(response.data.data.paymentIdentifier);

                    stopCamera();
                    setBooking(null);
                    setScanError(null);

                }).catch((error) => {
                console.log(error);
                // setScanError(error);
                // setScanError("We are sorry, the bar code you scanned was invalid. Kindly try again. For any further assistance, contact us.");
            });
    }

    const bookingForm = (

     <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Box component="form" aria-disabled={true} noValidate sx={{ mt: 3 }}>
                    <Grid container spacing={2}>

                        {(!scanError) ?
                            !!booking &&
                            <>
                                <Grid item xs={12} sm={12}>
                                    <Alert severity="success">Congratulations on becoming a part of Propertiesbag.com. Your booking has been confirmed.</Alert>
                                </Grid>

                                {/*Application Form Number*/}
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled={true}
                                            name="applicationFormNumber"
                                            required
                                            value={bookingApplicationFormNumber}
                                            fullWidth
                                            id="applicationFormNumber"
                                            label="Application Form Number"
                                        />
                                    </FormControl>
                                </Grid>

                                {/*Membership Id*/}
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled={true}
                                            name="membershipId"
                                            required
                                            value={bookingMembershipId}
                                            fullWidth
                                            id="membershipId"
                                            label="Membership ID"
                                        />
                                    </FormControl>
                                </Grid>

                                {/* Project Name */}
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled={true}
                                            name="projectName"
                                            required
                                            value={bookingProjectName}
                                            fullWidth
                                            id="projectName"
                                            label="Project Name"
                                        />
                                    </FormControl>
                                </Grid>

                                {/* Category */}
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="bookingCategory"
                                            required
                                            fullWidth
                                            id="bookingCategory"
                                            disabled={true}
                                            label="Category"
                                            autoComplete={"category"}
                                            value={bookingCategory}
                                        />
                                    </FormControl>
                                </Grid>

                                {/* Size */}
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="bookingSize"
                                            required
                                            fullWidth
                                            id="bookingSize"
                                            disabled={true}
                                            label="Size"
                                            autoComplete={"category"}
                                            value={bookingSize}
                                        />

                                    </FormControl>
                                </Grid>

                                {/* Customer Full Name  */}
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="customerFullName"
                                            required
                                            fullWidth
                                            disabled={true}
                                            id="customerFullName"
                                            label="Customer Name"
                                            value={applicantFullName}
                                            onChange={(event) => {
                                                setApplicantFullName(event.target.value);
                                            }
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </>
                            :
                            <>
                            <Grid item xs={12} sm={12}>
                                <Alert severity="error">{scanError}</Alert>
                            </Grid>
                            </>
                        }

                    </Grid>
                </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
        </Container>
    </ThemeProvider>

    );

    const camera = (

        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="md">
                <CssBaseline />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >

        <Grid container spacing={2}>

            {/*{this.html5Qrcode?.getState() !== Html5QrcodeScannerState.SCANNING &&*/}
            <Grid item xs={12} sm={6}>
                {/* Start camera on button click */}
                <Button
                    fullWidth
                    size={"large"}
                    variant="contained"
                    onClick={() => startCamera()}
                >
                    Start Scanning
                </Button>
            </Grid>
            {/*}*/}

            {/*{this.html5Qrcode?.getState() === Html5QrcodeScannerState.SCANNING &&*/}
            <Grid item xs={12} sm={6}>
                {/* Stop camera on button click */}
                <Button
                    fullWidth
                    size={"large"}
                    variant="contained"
                    color={"error"}
                    onClick={() => stopCamera()}
                >
                    Stop Scanning
                </Button>
            </Grid>
            {/*}*/}

            <Grid item xs={12} sm={12}>
                <div id={qrcodeRegionId} />
            </Grid>


        </Grid>

                </Box>
            </Container>
        </ThemeProvider>

    );

    // Use Effect
    useEffect(() => {

        setHtml5Qrcode(new Html5Qrcode(qrcodeRegionId, true));
    }
, []);


  return (
      <>
          {camera}

          {!!booking && bookingForm}
  </>
);
}