import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import Scrollbar from '../../components/scrollbar'
import {useParams} from 'react-router-dom'
import Services from '../../api/service'
import Logo from '../../images/properties_bag_logo.png'
import Footer from '../../components/footer';
import marketing from '../../images/service-single/marketing-single.jpg'


const ServiceMarketingSinglePage = (props) => {
    const { name } = useParams()

    const serviceDetails = Services.find(item => item.name === name)

    return (
        <Fragment>
            <Navbar Logo={Logo}/>
            <PageTitle pageTitle={"Marketing"} pagesub={'Services'} />
            <div className="wpo-service-single-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-12">
                            <div className="wpo-service-single-wrap">
                                <div className="wpo-service-single-item">
                                    <div className="wpo-service-single-main-img">
                                        <img src={marketing} alt=""/>
                                    </div>
                                    <div className="wpo-service-single-title">
                                        <h3>{"Marketing"}</h3>
                                    </div>
                                    <p>Propertiesbag.com handles the marketing of all the projects enlisted on its panel. The general principle behind our marketing practices is the provision of the actual image of the project without any exaggeration or moderation. We believe in introducing innovative marketing concepts to the real estate industry instead of conforming to the orthodox standards. In addition to print marketing, we have an astute team of digital marketing experts providing advanced marketing solutions based on data analysis and manipulation.</p>

                                    <div className="row mt-4">
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className="wpo-p-details-img">
                                                <img src="assets/images/service-single/2.jpg" alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className="wpo-p-details-img">
                                                <img src="assets/images/service-single/3.jpg" alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="wpo-service-single-item list-widget">
                                    <div className="wpo-service-single-title">
                                        <h3>Our Capabilities</h3>
                                    </div>
                                    <p>We provide the following services in the marketing spectrum:</p>
                                    <ul>
                                        <li>Concept design for an innovative marketing campaign.</li>
                                        <li>Planning and execution of the marketing campaign, with the thorough formulation of print, digital, and event-based marketing strategies.</li>
                                        <li>Digital marketing to expand product outreach, and gather data to study market trends, and provide an insight into the performance of the project and its image in the public.</li>
                                        <li>Optimal budgeting required for appropriate marketing of the project.</li>
                                    </ul>
                                </div>
                                <div className="wpo-service-single-item">
                                    <div className="wpo-service-single-title">
                                        <h3>Our approach</h3>
                                    </div>
                                    <p>The process starts with the design of the concept; a concept based on the characteristics of the project that make it unique and distinct, and market trends. After the formulation of the concept, strategies are developed based on the project traits, and the market is accordingly segmented. Through digital marketing techniques, data is collected regarding the performance and standing of the project in the market and public, based on which the marketing campaign is enhanced. Once the market analysis is complete, a full-fledged campaign is launched targeting all forms of media including print, electronic and social.</p>
                                </div>
                                <div className="wpo-service-single-item list-widget">
                                    <div className="wpo-service-single-title">
                                        <h3>Our Work Process</h3>
                                    </div>
                                    <ul>
                                        <li>Concept generation</li>
                                        <li>Formulation of preliminary strategies</li>
                                        <li>Data collection and analysis based on initial market performance</li>
                                        <li>Formulation of a thorough and enhanced marketing campaign based on the study of market trends and reviews.</li>
                                        <li>Launch of the finalized campaign on all the information mediums deemed necessary for marketing purposes.</li>

                                    </ul>
                                </div>
                                {/*<RelatedService/>*/}
                                {/* <Discuss/>*/}
                            </div>
                        </div>
                        {/*<ServiceSidebar/>*/}
                    </div>
                </div>
            </div>
            <Footer ftClass={'wpo-site-footer-s2'}/>
            <Scrollbar />
        </Fragment>
    )
};
export default ServiceMarketingSinglePage;
