import {Navigate, Outlet} from "react-router-dom";
import {Component} from "react";

export class ProtectedRoute extends Component<{ isAllowed: any, redirectPath: string, children: any }> {
    static defaultProps = {redirectPath: '/landing'}

    render() {
        let {
            isAllowed,
            redirectPath,
            children,
        } = this.props;

        if (!isAllowed) {
            return <Navigate to={redirectPath} replace/>;
        }

        return children ? children : <Outlet/>;
    }
}