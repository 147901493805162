import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {
    Avatar,
    Checkbox,
    FormControl,
    FormControlLabel, FormHelperText, InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import Api from "../../helpers/api";
import {useNavigate} from "react-router-dom";
import Sidebar from "../common/Sidebar";
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Copyright from "../common/Copyright";
import {VerifiedUser} from "@mui/icons-material";
import {User} from "../../models/user";
import {Controller, useForm} from "react-hook-form";
import ReactInputMask from "react-input-mask";

const theme = createTheme();

export default function NewUser() {
    const navigate = useNavigate();

    const [managers, setManagers] = React.useState([]);
    const [manager, setManager] = React.useState(new User({}));

    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [roles, setRoles] = React.useState([]);
    const [role, setRole] = React.useState('');
    const [cnic, setCnic] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [company, setCompany] = React.useState('');

    const [designations, setDesignations] = React.useState([]);
    const [designation, setDesignation] = React.useState('');

    const api = new Api();



    React.useEffect(() => {
        api.getAllManagers().then((response) => {
                setManagers(response.data.data);
            }
        );

        api.getAllManagerDesignations().then((response) => {
                setDesignations(response.data.data);
            }
        );

        api.getAllUserRoles().then((response) => {
            setRoles(response.data.data);
            }
        );
    }, []);

    const handleRoleChange = (event: SelectChangeEvent) => {
        setRole(event.target.value);

        if(event.target.value !== 'BOOKING_OFFICER') {
            setManager(new User({}));
        }
    }

    const handleManagerSelection = (event: SelectChangeEvent) => {

        // Extract from managers array
        let selectedManager = managers.filter((m: any) => m.id === event.target.value);

        if(selectedManager.length > 0) {
            setManager(selectedManager[0]);
        }

    }

    const { handleSubmit, control, reset, formState: { errors } } = useForm();
    const cellNumberMask = [/[3]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];


    const handleSubmitForm = (data: any) => {
        // event.preventDefault();
        // const data = new FormData(event.currentTarget);

        let user = {
            firstName: firstName,
            lastName: lastName,
            username: email,
            password: password,
            roles: [role],
            cnic: {
                number: cnic
            },
            phoneNumbers: [phone],
            // addressLocation: address,
            addressLocation: {
                address: {
                    addressOne: address
                }
            },
            companyName: company,
            designation: designation,
            manager: new User(manager)
        }

        api.registerUser(user).then((response) => {
            console.log(response);

            if(response.status === 200) {
                navigate('/users', {replace: true});
            }

        }).catch((error) => {
            console.log(error);
        });

    };



    const newUserForm = (

        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <VerifiedUser />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Add New User
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit(handleSubmitForm)} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>

                            {/* First Name */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <TextField
                                            name={name}
                                            required
                                            fullWidth
                                            id={name}
                                            label="First Name"
                                            error={Boolean(errors[name])}
                                            value={value}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            onChange={(event) => {
                                                onChange(event);
                                                setFirstName(event.target.value);
                                            }}
                                        />
                                    )}
                                    control={control}
                                    name={'firstName'}
                                    rules={{ required: { value: true, message: 'First name is required' } }}
                                />

                            </Grid>

                            {/* Last Name */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <TextField
                                            name={name}
                                            required
                                            fullWidth
                                            id={name}
                                            label="Last Name"
                                            error={Boolean(errors[name])}
                                            value={value}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            onChange={(event) => {
                                                onChange(event);
                                                setLastName(event.target.value);
                                            }}
                                        />
                                    )}
                                    control={control}
                                    name={'lastName'}
                                    rules={{ required: { value: true, message: 'Last name is required' } }}
                                />
                            </Grid>

                            {/* Email */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <TextField
                                            name={name}
                                            required
                                            type={'email'}
                                            fullWidth
                                            id={name}
                                            label="Email Address"
                                            error={Boolean(errors[name])}
                                            value={value}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            onChange={(event) => {
                                                onChange(event);
                                                setEmail(event.target.value);
                                            }}
                                        />
                                    )}
                                    control={control}
                                    name={'email'}
                                    rules={{ required: { value: true, message: 'Email is required' } }}
                                />
                            </Grid>

                            {/* Password */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <TextField
                                            name={name}
                                            required
                                            type="text"
                                            fullWidth
                                            id={name}
                                            label="Password"
                                            error={Boolean(errors[name])}
                                            value={value}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            onChange={(event) => {
                                                onChange(event);
                                                setPassword(event.target.value);
                                            }}
                                        />
                                    )}
                                    control={control}
                                    name={'password'}
                                    rules={{ required: { value: true, message: 'Password is required' } }}
                                />
                            </Grid>

                            {/* CNIC Number */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <ReactInputMask
                                            mask={"99999-9999999-9"}
                                            value={value}
                                            disabled={false}
                                            onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => {
                                                onChange(event);
                                                setCnic(event.target.value);
                                            }}
                                        >
                                            <TextField
                                                name={name}
                                                required
                                                fullWidth
                                                id={name}
                                                label="CNIC"
                                                error={Boolean(errors[name])}
                                                value={value}
                                                helperText={errors[name] ? String(errors[name]?.message) : ""}
                                                placeholder={"12345-1234567-1"}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </ReactInputMask>
                                    )}
                                    control={control}
                                    name={'cnic'}
                                    rules={{ required: { value: true, message: 'CNIC is required' } }}
                                />
                            </Grid>


                            {/* Phone Number */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <ReactInputMask
                                            mask={cellNumberMask}
                                            value={value}
                                            disabled={false}
                                            onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => {
                                                onChange(event);
                                                setPhone(event.target.value);
                                            }}
                                        >
                                            <TextField
                                                name={name}
                                                required
                                                fullWidth
                                                id={name}
                                                label="Cell Number"
                                                error={Boolean(errors[name])}
                                                value={value}
                                                helperText={errors[name] ? String(errors[name]?.message) : ""}
                                                placeholder={"3331234567"}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">+92</InputAdornment>,
                                                }}
                                            />
                                        </ReactInputMask>
                                    )}
                                    control={control}
                                    name={'phone'}
                                    rules={{ required: { value: true, message: 'Cell number is required' } }}
                                />
                            </Grid>


                            {/* Company Name */}
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <TextField
                                            name={name}
                                            required
                                            fullWidth
                                            id={name}
                                            label="Company Name"
                                            error={Boolean(errors[name])}
                                            value={value}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            onChange={(event) => {
                                                onChange(event);
                                                setCompany(event.target.value);
                                            }}
                                        />
                                    )}
                                    control={control}
                                    name={'company'}
                                    rules={{ required: { value: true, message: 'Company name is required' } }}
                                />
                            </Grid>

                            {/* Role */}
                            <Grid item xs={12}>
                                <FormControl fullWidth>

                                    <Controller
                                        render={({ field: { name, value, onChange } }) => (
                                            <FormControl fullWidth>
                                                <InputLabel id="roleLabel">Role</InputLabel>
                                                <Select
                                                    labelId="roleLabel"
                                                    name={name}
                                                    id={name}
                                                    label="Role"
                                                    error={Boolean(errors[name])}
                                                    value={value}
                                                    onChange={(event) => {
                                                        onChange(event);
                                                        handleRoleChange(event);
                                                    }}
                                                >
                                                   {/* <MenuItem value={"CEO"}>CEO</MenuItem>
                                                    <MenuItem value={"ACCOUNT_MANAGER"}>Account Manager</MenuItem>
                                                    <MenuItem value={"BOOKING_OFFICER"}>Booking Officer</MenuItem>
                                                    <MenuItem value={"SALES_MANAGER"}>Sales Manager</MenuItem>*/}
                                                    {roles.map((m: any) => (
                                                        <MenuItem value={m}>{m}</MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText error={Boolean(errors[name])}>{errors[name] ? String(errors[name]?.message) : ""}</FormHelperText>
                                            </FormControl>

                                        )}
                                        control={control}
                                        name={'role'}
                                        rules={{ required: { value: true, message: 'Role is required' } }}
                                    />

                                </FormControl>
                            </Grid>

                            {/* Manager */}
                            {role === 'BOOKING_OFFICER' && <Grid item xs={12}>
                                <FormControl fullWidth>

                                    <InputLabel id="managerLabel">Manager</InputLabel>
                                    <Select
                                        labelId="managerLabel"
                                        id="manager"
                                        label="Manager"
                                        value={manager.id}
                                        onChange={(newValue) => {
                                            // setManager(newValue.target.value);
                                            handleManagerSelection(newValue);
                                        }}
                                    >
                                        <MenuItem value={"none"}><em>None</em></MenuItem>
                                        {managers.map((m: any) => (
                                            <MenuItem value={m.id}>{m.firstName} {m.lastName}</MenuItem>
                                        ))}

                                    </Select>

                                </FormControl>
                            </Grid>

                            }

                            {/* Designation */}
                            {role === 'SALES_MANAGER' || role === 'SALES_PARTNER' && <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Controller
                                        render={({ field: { name, value, onChange } }) => (
                                            <FormControl fullWidth>
                                                <InputLabel id="designationLabel">Designation</InputLabel>
                                                <Select
                                                    labelId="designationLabel"
                                                    name={name}
                                                    id={name}
                                                    label="Designation"
                                                    error={Boolean(errors[name])}
                                                    value={value}
                                                    onChange={(event) => {
                                                        onChange(event);
                                                        setDesignation(event.target.value);
                                                    }}
                                                >
                                                    {designations.map((m: any) => (
                                                        <MenuItem value={m}>{m}</MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText error={Boolean(errors[name])}>{errors[name] ? String(errors[name]?.message) : ""}</FormHelperText>
                                            </FormControl>

                                        )}
                                        control={control}
                                        name={'designation'}
                                        rules={{ required: { value: true, message: 'Designation is required' } }}
                                    />

                                </FormControl>
                            </Grid>
                            }


                            {/* Address */}
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <TextField
                                            name={name}
                                            required
                                            fullWidth
                                            id={name}
                                            label="Address"
                                            error={Boolean(errors[name])}
                                            value={value}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            onChange={(event) => {
                                                onChange(event);
                                                setAddress(event.target.value);
                                            }}
                                        />
                                    )}
                                    control={control}
                                    name={'address'}
                                    rules={{ required: { value: true, message: 'Address is required' } }}
                                />
                            </Grid>

                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign Up
                        </Button>

                    </Box>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </ThemeProvider>

    );


    return <Sidebar childComponent={newUserForm} index={3}  title={"Add New User"} />;
}