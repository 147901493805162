import * as React from 'react';
import {ChangeEvent, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {createTheme} from '@mui/material/styles';
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {TextResult} from "dynamsoft-javascript-barcode";

import {
    Chip,
    Divider,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import Api from "../../../helpers/api";
import jwt_decode from "jwt-decode";
import ReactInputMask from "react-input-mask";
import {Controller, useForm} from "react-hook-form";

const theme = createTheme();

const bucketName = process.env.REACT_APP_BUCKET_NAME;
export default function BookingFormStep1(props:any) {

    const handleNext = props.handleNext;


    const api = new Api();

    const jwtDecoded:any = localStorage.getItem("accessToken") !== null ? jwt_decode(localStorage.getItem(`accessToken`) as string) : null;

    const [pricing, setPricing] = React.useState([]);

    const [bookingApplicationFormNumber, setBookingApplicationFormNumber] = React.useState('001150');
    const [bookingDate, setBookingDate] = React.useState<Date | null>(new Date());
    const [bookingDealCode, setBookingDealCode] = React.useState('');
    const [bookingProjectName, setBookingProjectName] = React.useState('');

    // Customer Details
    const [applicantFullName, setApplicantFullName] = React.useState('');
    const [applicantCnic, setApplicantCnic] = React.useState('');

    const [applicantDob, setApplicantDob] = React.useState<Date | null>(null);
    const [applicantEmail, setApplicantEmail] = React.useState('');
    const [applicantContactNumber, setApplicantContactNumber] = React.useState('');

    const [applicantMailingAddress, setApplicantMailingAddress] = React.useState('');

    const [applicantPermanentAddress, setApplicantPermanentAddress] = React.useState('');

    // Customer - Next of Kin Details
    const [nextOfKinFullName, setNextOfKinFullName] = React.useState('');
    const [nextOfKinCnic, setNextOfKinCnic] = React.useState('');

    const [nextOfKinRelationship, setNextOfKinRelationship] = React.useState('');
    const [nextOfKinEmail, setNextOfKinEmail] = React.useState('');
    const [nextOfKinContactNumber, setNextOfKinContactNumber] = React.useState('');

    const [nextOfKinMailingAddress, setNextOfKinMailingAddress] = React.useState('');
    const [nextOfKinPermanentAddress, setNextOfKinPermanentAddress] = React.useState('');


    // Booking Details
    const [bookingCategory, setBookingCategory] = React.useState('');
    const [bookingSize, setBookingSize] = React.useState('');

    const [realtorCompanyName, setRealtorCompanyName] = React.useState('');
    const [realtorContactNumber, setRealtorContactNumber] = React.useState('');
    const [realtorRepresentativeName, setRealtorRepresentativeName] = React.useState('');
    const [realtorRepresentativeCnic, setRealtorRepresentativeCnic] = React.useState('');


    // Payment Details
    const [bookingModeOfPayment, setBookingModeOfPayment] = React.useState('');
    const [bookingPrice, setBookingPrice] = React.useState('');

    const [bookingBankName, setBookingBankName] = React.useState('');
    const [bookingBankBranchName, setBookingBankBranchName] = React.useState('');

    const [bookingDsDdPoNo, setBookingDsDdPoNo] = React.useState('');

    const handleDealCodeChange = (event: SelectChangeEvent) => {
        setBookingPrice('');

        setBookingDealCode(event.target.value);

        setBookingCategory(getSingleUniqueCategory(bookingProjectName, event.target.value)[0]);

/*        if(event.target.value === '01') {
            setBookingCategory('Residential');
        }
        else if(event.target.value === '02') {
            setBookingCategory('Residential / Overseas Block');
        }*/



    }

    const handleBookingSizeChange = (event: SelectChangeEvent) => {
        const currentBookingSize = event.target.value as string;
        setBookingSize(currentBookingSize as string);

        // console.log(getUniquePrices(bookingProjectName, bookingDealCode, bookingCategory, currentBookingSize));

        setBookingPrice((getUniquePrices(bookingProjectName, bookingDealCode, bookingCategory, currentBookingSize)[0]));

        /*if(bookingDealCode === '01') {

            if (currentBookingSize === '7 Marla') {
                setBookingPrice('1540000');
            } else if (currentBookingSize === '10 Marla') {
                setBookingPrice('2000000');
            } else if (currentBookingSize === '1 Kanal') {
                setBookingPrice('3600000');
            } else if (currentBookingSize === '2 Kanal') {
                setBookingPrice('6400000');
            }
        }
        else if(bookingDealCode === '02') {
            if (currentBookingSize === '7 Marla') {
                setBookingPrice('847000');
            } else if (currentBookingSize === '10 Marla') {
                setBookingPrice('1100000');
            } else if (currentBookingSize === '1 Kanal') {
                setBookingPrice('1980000');
            } else if (currentBookingSize === '2 Kanal') {
                setBookingPrice('3520000');
            }
        }*/

    }



    const handleSubmitForm = (data: any) => {
        // event.preventDefault();
        // const data = new FormData(event.currentTarget);

        let applicant = {
            fullName: applicantFullName,
            cnic: {
                number: applicantCnic,
            },
            dateOfBirth: applicantDob,
            email: applicantEmail,
            phone: applicantContactNumber,
            mailingAddress: applicantMailingAddress,
            permanentAddress: applicantPermanentAddress
        };

        let nextOfKin = {
            fullName: nextOfKinFullName,
            cnic: {
                number: nextOfKinCnic,
            },
            relationship: nextOfKinRelationship,
            email: nextOfKinEmail,
            phone: nextOfKinContactNumber,
            mailingAddress: nextOfKinMailingAddress,
            permanentAddress: nextOfKinPermanentAddress
        };

        let booking = {
            // projectName: "Affinity Enclave",
            projectName: bookingProjectName,
            formNumber: bookingApplicationFormNumber,
            bookingDate: bookingDate,
            dealCode: bookingDealCode,
            category: bookingCategory,
            size: bookingSize,
            bookingPrice: bookingPrice,
            modeOfPayment: bookingModeOfPayment,
            bankName: bookingBankName,
            bankBranch: bookingBankBranchName,
            paymentIdentifier: bookingDsDdPoNo,
/*            realtorCompany: realtorCompanyName,
            realtorPhone: realtorContactNumber,
            realtorRepresentative: realtorRepresentativeName,
            realtorCnic: realtorRepresentativeCnic,*/
            realtor: {
                id: jwtDecoded.uid
            },
            applicant: applicant,
            nextOfKin: nextOfKin
        }

        api.createNewBooking(booking).then((response) => {
            console.log(response);
            handleNext(response.data.data);
        }).catch((error) => {
            console.log(error);
        });

    };

    // Use effect
    React.useEffect(() => {
        if(jwtDecoded) {
            api.getUserById(jwtDecoded.uid)
                .then((response) => {
                    setRealtorCompanyName(response.data.data.companyName);
                    setRealtorRepresentativeName(response.data.data.firstName + ' ' + response.data.data.lastName);
                    setRealtorRepresentativeCnic(response.data.data.cnic.number);
                    setRealtorContactNumber(response.data.data.phoneNumbers[0]);
                }).catch((error) => {
                console.log(error);
            });


            api.getAllProjectPricing().then((response) => {
                setPricing(response.data.data);
            }).catch((error) => {
                console.log(error);
            });

            console.log("Booking Project Name:" + bookingProjectName);
        }
    }, []);


    // Get all unique project names from pricing
    const getUniqueProjectNames = () => {
        return pricing.map((pricing:any) => pricing.projectName)
            .filter((value:any, index:any, self:any) => self.indexOf(value) === index);
    }

    // Get all unique deal codes from pricing for a specific project
    const getUniqueDealCodes = (projectName:string) => {
        return pricing.filter((pricing:any) => pricing.projectName === projectName)
            .map((pricing:any) => pricing.dealCode)
            .filter((value:any, index:any, self:any) => self.indexOf(value) === index);
    }

    const getUniqueCategories = (projectName:string, dealCode:string) => {
        return pricing.filter((pricing:any) => pricing.projectName === projectName && pricing.dealCode === dealCode)
            .map((pricing:any) => pricing.category)
            .filter((value:any, index:any, self:any) => self.indexOf(value) === index);
    }

    const getSingleUniqueCategory = (projectName:string, dealCode:string) => {
        return pricing.filter((pricing:any) => pricing.projectName === projectName && pricing.dealCode === dealCode)
            .map((pricing:any) => pricing.category)
            .filter((value:any, index:any, self:any) => self.indexOf(value) === index);
    }

    const getUniqueSizes = (projectName:string, dealCode:string, category:string) => {
        return pricing.filter((pricing:any) => pricing.projectName === projectName && pricing.dealCode === dealCode && pricing.category == category)
            .map((pricing:any) => pricing.size)
            .filter((value:any, index:any, self:any) => self.indexOf(value) === index);
    }

    const getUniquePrices = (projectName:string, dealCode:string, category:string, size:string) => {
        return pricing.filter((pricing:any) => pricing.projectName === projectName && pricing.dealCode === dealCode && pricing.category === category && pricing.size === size)
            .map((pricing:any) => pricing.bookingPrice)
            .filter((value:any, index:any, self:any) => self.indexOf(value) === index)
    }


    const handleFileUploadError = (error:any) => {
        // Do something...
    }

    const handleFilesChange = (files:any) => {
        // Do something...
    }

    const [applicantImageUrl, setApplicantImageUrl] = React.useState('');
    const [selectedApplicantImage, setSelectedApplicantImage] = React.useState<File | null>(null);
    const [selectedApplicantImageName, setSelectedApplicantImageName] = React.useState<string | null>(null);


    const [applicantCnicImage, setApplicantCnicImage] = React.useState();
    const [nextOfKinCnicImage, setNextOfKinCnicImage] = React.useState();
    const [poImage, setPoImage] =  React.useState();

    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#0960e6");

    const handleApplicantImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return
        setSelectedApplicantImage(event.target.files[0]);
        setSelectedApplicantImageName(event.target.files[0].name);

        uploadImage();
    }

    const uploadImage = () => {

        setLoading(true);

        const params = {
            file: selectedApplicantImage,
            fileName: selectedApplicantImageName,
        }

        api.uploadImage(params).then((response) => {
                console.log(response);
            setApplicantImageUrl(`https://${bucketName}.s3.amazonaws.com/${params.fileName}`);
            setLoading(false);
            selectedApplicantImage && setSelectedApplicantImage(null);
            }
        ).catch((error) => {
                console.log(error);
            setLoading(false);
            }
        );
    }


    /* BAR CODE  */

    const [isActive,setIsActive] = React.useState(true); //whether the camera is active
    const [initialized, setInitialized] = React.useState(false); //whether the camera is initialized
    const [isPause,setIsPause] = React.useState(false); //whether the video is paused
    const [runtimeSettings,setRuntimeSettings] = React.useState("{\"ImageParameter\":{\"BarcodeFormatIds\":[\"BF_QR_CODE\"],\"Description\":\"\",\"Name\":\"Settings\"},\"Version\":\"3.0\"}"); //use JSON template to decode QR codes only
    const [barCodeResult, setBarCodeResult] = React.useState(''); //the result of the barcode scan
    const onOpened = (cam:HTMLVideoElement,camLabel:string) => { // You can access the video element in the onOpened event
        console.log("opened");
    }

    const onClosed = () => {
        console.log("closed");
    }

    const onDeviceListLoaded = (devices:MediaDeviceInfo[]) => {
        console.log(devices);
    }

    const onScanned = (results:TextResult[]) => { // barcode results
        console.log(results);
    }

    const onClicked = (result:TextResult) => { // when a barcode overlay is clicked
        alert(result.barcodeText);
        setBarCodeResult(result.barcodeText);
    }

    const onInitialized = () => { // when the Barcode Reader is initialized
        setInitialized(true);
    }





    const onNewScanResult = (decodedText:any, decodedResult:any) => {
        alert(decodedText);
    }

    const cellNumberMask = [/[3]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
    const cnicMask = [/[0-9]{5}/, "-", /[0-9]{7}/, "-", /[0-9]{1}/];
    // const emailMask = [/[a-zA-Z0-9]/,'@',/[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*/];
    // const emailMask = [/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/];


    const { handleSubmit, control, reset, formState: { errors } } = useForm();


    const bookingForm = (

            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    // alignItems: 'center',
                }}
            >

                <Grid container textAlign={"center"} alignItems={"center"} spacing={2}>
                    <Grid item xs={12} sm={12}>
                        <Typography variant={"h4"} >
                            Application Form
                        </Typography>
                    </Grid>
                </Grid>

                <Box component="form" noValidate onSubmit={handleSubmit(handleSubmitForm)}  sx={{ mt: 3 }}>
                    <Grid container spacing={2}>

                        {/* Date */}
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    disablePast={true}
                                    label="Date"
                                    value={bookingDate}
                                    inputFormat={"DD/MM/YYYY"}
                                    onChange={(newValue) => {
                                        setBookingDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            </FormControl>
                        </Grid>

                        {/* Project Name */}
                        <Grid item xs={12} sm={4}>
                            <Controller
                                render={({ field: { name, value, onChange } }) => (
                                    <FormControl fullWidth>
                                        <InputLabel id="projectNameLabel">Project Name</InputLabel>
                                        <Select
                                            labelId="projectNameLabel"
                                            name={name}
                                            id={'projectName'}
                                            label="Project Name"
                                            error={Boolean(errors["projectName"])}
                                            value={value}
                                            onChange={(event) => {
                                                onChange(event);
                                                setBookingProjectName(event.target.value);
                                            }}

                                            // projectNames={projectNames}
                                        >
                                            {/*<MenuItem value={"Affinity Enclave"} selected={true}>Affinity Enclave</MenuItem>*/}
                                            {/*<MenuItem value={"02"}>02</MenuItem>*/}

                                            {getUniqueProjectNames().map((m: any) => (
                                                <MenuItem key={m} value={m} selected={false}>{m}</MenuItem>
                                            ))}

                                        </Select>
                                        <FormHelperText error={Boolean(errors["projectName"])}>{errors["projectName"] ? String(errors["projectName"].message) : ""}</FormHelperText>
                                    </FormControl>

                                )}
                                control={control}
                                name={'projectName'}
                                rules={{ required: { value: true, message: 'Project Name is required' } }}
                            />

                        </Grid>

                        {/* Deal Code */}
                        <Grid item xs={12} sm={4}>
                            <Controller
                                render={({ field: { name, value, onChange } }) => (
                                    <FormControl fullWidth>
                                    <InputLabel id="dealCodeLabel">Deal Code</InputLabel>
                                    <Select
                                        disabled={!bookingProjectName}
                                    labelId="dealCodeLabel"
                                    name={name}
                                    id={'dealCode'}
                                    label="Deal Code"
                                    error={Boolean(errors["dealCode"])}
                                    value={value}
                                    onChange={(event) => {
                                        onChange(event);
                                        handleDealCodeChange(event);
                                    }}
                                    >
{/*                                    <MenuItem value={"01"}>01</MenuItem>
                                    <MenuItem value={"02"}>02</MenuItem>*/}

                                        {getUniqueDealCodes(bookingProjectName).map((m: any) => (
                                            <MenuItem key={m} value={m}>{m}</MenuItem>
                                        ))}

                                    </Select>
                                        <FormHelperText error={Boolean(errors["dealCode"])}>{errors["dealCode"] ? String(errors["dealCode"].message) : ""}</FormHelperText>
                                    </FormControl>

                                )}
                                control={control}
                                name={'dealCode'}
                                rules={{ required: { value: true, message: 'Deal Code is required' } }}
                            />

                        </Grid>

                        {/* Customer Full Name  */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <TextField
                                            name={name}
                                            required
                                            fullWidth
                                            id={name}
                                            label="Customer Name"
                                            error={Boolean(errors[name])}
                                            value={value}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            onChange={(event) => {
                                                onChange(event);
                                                setApplicantFullName(event.target.value);
                                            }}
                                        />
                                    )}
                                    control={control}
                                    name={'customerFullName'}
                                    rules={{ required: { value: true, message: 'Customer name is required' } }}
                                />
                            </FormControl>
                        </Grid>

                        {/* Customer CNIC  */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <ReactInputMask
                                            mask={"99999-9999999-9"}
                                            value={value}
                                            disabled={false}
                                            onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => {
                                                onChange(event);
                                                setApplicantCnic(event.target.value);
                                            }}
                                        >
                                            <TextField
                                                name={name}
                                                required
                                                fullWidth
                                                id={name}
                                                label="CNIC"
                                                error={Boolean(errors[name])}
                                                value={value}
                                                helperText={errors[name] ? String(errors[name]?.message) : ""}
                                                placeholder={"12345-1234567-1"}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </ReactInputMask>
                                    )}
                                    control={control}
                                    name={'customerCnic'}
                                    rules={{ required: { value: true, message: 'Customer CNIC is required' } }}
                                />

                            </FormControl>
                        </Grid>

                        {/* Customer Date of Birth */}
                        <Grid item xs={12} sm={4}>


                            <Controller
                                render={({ field: { name, value, onChange } }) => (

                                        /*<TextField
                                            name={name}
                                            required
                                            fullWidth
                                            id={name}
                                            label="Cell Number"
                                            error={Boolean(errors[name])}
                                            value={value}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            placeholder={"3331234567"}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">+92</InputAdornment>,
                                            }}
                                        />*/

                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <DatePicker
                                    label="Date of Birth"
                                    inputFormat={"DD/MM/YYYY"}
                                    value={value}
                                    onChange={(newValue) => {
                                        onChange(newValue)
                                        setApplicantDob(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name={name}
                                            required
                                            fullWidth
                                            id={name}
                                            error={Boolean(errors[name])}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            placeholder={"3331234567"}
                                        />

                                    )
                                    }
                                    />
                                    </LocalizationProvider>



                                )}
                                control={control}
                                name={'customerDob'}
                                rules={{ required: { value: true, message: 'Customer date of birth is required' } }}
                            />




                        </Grid>

                        {/* Customer Email  */}
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <TextField
                                            name={name}
                                            fullWidth
                                            id={name}
                                            label="Email"
                                            error={Boolean(errors[name])}
                                            value={value}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            onChange={(event) => {
                                                onChange(event);
                                                setApplicantEmail(event.target.value);
                                            }}
                                        />

                                    )}
                                    control={control}
                                    name={'customerEmail'}
                                    rules={{ required: { value: false, message: 'Customer email is required' } }}
                                />


                            </FormControl>
                        </Grid>

                        {/* Customer Cell Number  */}
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <ReactInputMask
                                            mask={cellNumberMask}
                                            value={value}
                                            disabled={false}
                                            onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => {
                                                onChange(event);
                                                setApplicantContactNumber(event.target.value);
                                            }}
                                        >
                                            <TextField
                                                name={name}
                                                required
                                                fullWidth
                                                id={name}
                                                label="Cell Number"
                                                error={Boolean(errors[name])}
                                                value={value}
                                                helperText={errors[name] ? String(errors[name]?.message) : ""}
                                                placeholder={"3331234567"}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">+92</InputAdornment>,
                                                }}
                                            />
                                        </ReactInputMask>
                                    )}
                                    control={control}
                                    name={'customerCellNumber'}
                                    rules={{ required: { value: true, message: 'Customer cell number is required' } }}
                                />
                            </FormControl>
                        </Grid>

                        {/* Customer Mailing Address  */}
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <TextField
                                            name={name}
                                            required
                                            fullWidth
                                            id={name}
                                            label="Mailing Address"
                                            error={Boolean(errors[name])}
                                            value={value}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            onChange={(event) => {
                                                onChange(event);
                                                setApplicantMailingAddress(event.target.value);
                                            }}
                                        />
                                    )}
                                    control={control}
                                    name={'customerMailAddress'}
                                    rules={{ required: { value: true, message: 'Customer mailing address is required' } }}
                                />
                            </FormControl>
                        </Grid>

                        {/* Customer Permanent Address  */}
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <TextField
                                            name={name}
                                            required
                                            fullWidth
                                            id={name}
                                            label="Permanent Address"
                                            error={Boolean(errors[name])}
                                            value={value}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            onChange={(event) => {
                                                onChange(event);
                                                setApplicantPermanentAddress(event.target.value);
                                            }}
                                        />
                                    )}
                                    control={control}
                                    name={'customerPermanentAddress'}
                                    rules={{ required: { value: true, message: 'Customer permanent address is required' } }}
                                />
                            </FormControl>
                        </Grid>

                        <Divider variant={"middle"} flexItem />

                        {/* Next of kin declaration  */}
                        <Grid mt={3} item xs={12} textAlign={"center"} alignItems={"center"} >
                            <Chip label="I hereby nominate the following as my next of kin (attach copy)" color="primary" />
                        </Grid>

                        {/* Next of Kin Full Name  */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <TextField
                                            name={name}
                                            required
                                            fullWidth
                                            id={name}
                                            label="Full Name"
                                            error={Boolean(errors[name])}
                                            value={value}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            onChange={(event) => {
                                                onChange(event);
                                                setNextOfKinFullName(event.target.value);
                                            }}
                                        />
                                    )}
                                    control={control}
                                    name={'nextOfKinFullName'}
                                    rules={{ required: { value: true, message: 'Next of kin full name is required' } }}
                                />

                            </FormControl>
                        </Grid>

                        {/* Next of Kin CNIC  */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <ReactInputMask
                                            mask={"99999-9999999-9"}
                                            value={value}
                                            disabled={false}
                                            onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => {
                                                onChange(event);
                                                setNextOfKinCnic(event.target.value);
                                            }}
                                        >
                                            <TextField
                                                name={name}
                                                required
                                                fullWidth
                                                id={name}
                                                label="CNIC"
                                                error={Boolean(errors[name])}
                                                value={value}
                                                helperText={errors[name] ? String(errors[name]?.message) : ""}
                                                placeholder={"12345-1234567-1"}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </ReactInputMask>
                                    )}
                                    control={control}
                                    name={'nextOfKinCnic'}
                                    rules={{ required: { value: true, message: 'Next of kin CNIC is required' } }}
                                />




                            </FormControl>
                        </Grid>

                        {/* Next of Kin Relationship  */}
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <TextField
                                            name={name}
                                            fullWidth
                                            id={name}
                                            required
                                            label="Relationship"
                                            error={Boolean(errors[name])}
                                            value={value}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            onChange={(event) => {
                                                onChange(event);
                                                setNextOfKinRelationship(event.target.value);
                                            }}
                                        />

                                    )}
                                    control={control}
                                    name={'nextOfKinRelationship'}
                                    rules={{ required: { value: true, message: 'Next of kin relationship is required' } }}
                                />

                            </FormControl>
                        </Grid>

                        {/* Next of Kin Email  */}
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <TextField
                                            name={name}
                                            fullWidth
                                            id={name}
                                            label="Email"
                                            error={Boolean(errors[name])}
                                            value={value}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            onChange={(event) => {
                                                onChange(event);
                                                setNextOfKinEmail(event.target.value);
                                            }}
                                        />

                                    )}
                                    control={control}
                                    name={'nextOfKinEmail'}
                                    rules={{ required: { value: false, message: 'Next of kin email is required' } }}
                                />

                            </FormControl>
                        </Grid>

                        {/* Next of Kin Cell Number  */}
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <ReactInputMask
                                            mask={cellNumberMask}
                                            value={value}
                                            disabled={false}
                                            onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => {
                                                onChange(event);
                                                setNextOfKinContactNumber(event.target.value);
                                            }}
                                        >
                                            <TextField
                                                name={name}
                                                required
                                                fullWidth
                                                id={name}
                                                label="Cell Number"
                                                error={Boolean(errors[name])}
                                                value={value}
                                                helperText={errors[name] ? String(errors[name]?.message) : ""}
                                                placeholder={"3331234567"}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">+92</InputAdornment>,
                                                }}
                                            />
                                        </ReactInputMask>
                                    )}
                                    control={control}
                                    name={'nextOfKinCellNumber'}
                                    rules={{ required: { value: true, message: 'Next of kin cell number is required' } }}
                                />

                            </FormControl>
                        </Grid>

                        {/* Next of Kin Mailing Address  */}
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <TextField
                                            name={name}
                                            required
                                            fullWidth
                                            id={name}
                                            label="Mailing Address"
                                            error={Boolean(errors[name])}
                                            value={value}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            onChange={(event) => {
                                                onChange(event);
                                                setNextOfKinMailingAddress(event.target.value);
                                            }}
                                        />
                                    )}
                                    control={control}
                                    name={'nextOfKinMailAddress'}
                                    rules={{ required: { value: true, message: 'Next of kin mailing address is required' } }}
                                />
                            </FormControl>
                        </Grid>

                        {/* Next of Kin Permanent Address  */}
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <TextField
                                            name={name}
                                            required
                                            fullWidth
                                            id={name}
                                            label="Permanent Address"
                                            error={Boolean(errors[name])}
                                            value={value}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            onChange={(event) => {
                                                onChange(event);
                                                setNextOfKinPermanentAddress(event.target.value);
                                            }}
                                        />
                                    )}
                                    control={control}
                                    name={'nextOfKinPermanentAddress'}
                                    rules={{ required: { value: true, message: 'Next of kin permanent address is required' } }}
                                />
                            </FormControl>
                        </Grid>

                        {/* Empty Space  */}
                        <Grid mt={3} item xs={12}>
                        </Grid>

                        {/* Category */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>

                                <InputLabel id="bookingCategoryLabel">Category</InputLabel>
                                <Select
                                    labelId="bookingCategoryLabel"
                                    id="bookingCategory"
                                    disabled={!bookingDealCode}
                                    value={bookingCategory}
                                    label="Category"
                                    onChange={(newValue) => {
                                        setBookingCategory(newValue.target.value);
                                    }}
                                >

                                    {/*{bookingDealCode === "01" && <MenuItem value={"Residential"}>Residential</MenuItem>}*/}
                                    {/*{bookingDealCode === "02" && <MenuItem value={"Residential / Overseas Block"}>Residential / Overseas Block</MenuItem>}*/}


                                    {getUniqueCategories(bookingProjectName, bookingDealCode).map((m: any) => (
                                        <MenuItem value={m}>{m}</MenuItem>
                                    ))
                                    }

                                </Select>

                            </FormControl>
                        </Grid>

                        {/* Size */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <FormControl fullWidth>
                                            <InputLabel id="bookingSizeLabel">Size</InputLabel>
                                            <Select
                                                labelId="bookingSizeLabel"
                                                disabled={!bookingCategory}
                                                name={name}
                                                id={name}
                                                label="Size"
                                                error={Boolean(errors[name])}
                                                value={value}
                                                onChange={(event) => {
                                                    onChange(event);
                                                    handleBookingSizeChange(event);
                                                }}
                                            >
                                                {/*<MenuItem value={"7 Marla"}>7 Marla</MenuItem>
                                                <MenuItem value={"10 Marla"}>10 Marla</MenuItem>
                                                <MenuItem value={"1 Kanal"}>1 Kanal</MenuItem>
                                                <MenuItem value={"2 Kanal"}>2 Kanal</MenuItem>*/}

                                                {getUniqueSizes(bookingProjectName, bookingDealCode, bookingCategory).map((m: any) => (
                                                    <MenuItem value={m}>{m}</MenuItem>
                                                ))
                                                }

                                            </Select>
                                            <FormHelperText error={Boolean(errors[name])}>{errors[name] ? String(errors[name]?.message) : ""}</FormHelperText>
                                        </FormControl>

                                    )}
                                    control={control}
                                    name={'bookingSize'}
                                    rules={{ required: { value: true, message: 'Size is required' } }}
                                />



                            </FormControl>
                        </Grid>


                        {/* Company Name  */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    name="realtorCompanyName"
                                    required
                                    disabled
                                    fullWidth
                                    id="realtorCompanyName"
                                    label="Company Name"
                                    value={realtorCompanyName}
                                    onChange={(newValue) => {
                                        setRealtorCompanyName(newValue.target.value);
                                    }
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {/* Realtor Contact Number  */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    name="realtorContactNumber"
                                    required
                                    disabled
                                    fullWidth
                                    id="realtorContactNumber"
                                    label="Contact Number"
                                    value={realtorContactNumber}
                                    onChange={(newValue) => {
                                        setRealtorContactNumber(newValue.target.value);
                                    }
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {/* Rep Name  */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    name="realtorRepName"
                                    required
                                    disabled
                                    fullWidth
                                    id="realtorRepName"
                                    label="Rep Name"
                                    value={realtorRepresentativeName}
                                    onChange={(newValue) => {
                                        setRealtorRepresentativeName(newValue.target.value);
                                    }
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {/* Realtor CNIC  */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>

                                <ReactInputMask
                                    mask="99999-9999999-9"
                                    value={realtorRepresentativeCnic}
                                    disabled={true}
                                    onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => {
                                        setRealtorRepresentativeCnic(event.target.value);
                                    }
                                    }
                                >
                                    <TextField
                                        name="realtorCnic"
                                        required
                                        fullWidth
                                        id="realtorCnic"
                                        label="CNIC"
                                    />

                                </ReactInputMask>

                            </FormControl>

                        </Grid>

                        {/* Empty Space  */}
                        <Grid mt={3} item xs={12}>
                        </Grid>


                        {/* Mode of Payment */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <FormControl fullWidth>
                                            <InputLabel id="bookingModeOfPaymentLabel">Mode of Payment</InputLabel>
                                            <Select
                                                labelId="bookingModeOfPaymentLabel"
                                                name={name}
                                                id={name}
                                                label="Mode of Payment"
                                                error={Boolean(errors[name])}
                                                value={value}
                                                onChange={(event) => {
                                                    onChange(event);
                                                    setBookingModeOfPayment(event.target.value);
                                                }}
                                            >
                                                <MenuItem value={"Sales Partner Account"}>Sales Partner Account</MenuItem>
                                                <MenuItem value={"Deposit Slip"}>Deposit Slip</MenuItem>
                                                <MenuItem value={"Demand Draft"}>Demand Draft</MenuItem>
                                                <MenuItem value={"Purchase Order"}>Purchase Order</MenuItem>
                                            </Select>
                                            <FormHelperText error={Boolean(errors[name])}>{errors[name] ? String(errors[name]?.message) : ""}</FormHelperText>
                                        </FormControl>

                                    )}
                                    control={control}
                                    name={'bookingModeOfPayment'}
                                    rules={{ required: { value: true, message: 'Mode of payment is required' } }}
                                />

                            </FormControl>
                        </Grid>

                        {/* Booking Price  */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    name="bookingPrice"
                                    value={bookingPrice}
                                    required
                                    fullWidth
                                    disabled
                                    id="bookingPrice"
                                    label="Booking Price"
                                />


                               {/* <Controller
                                    render={({ field: { name } }) => (
                                        <TextField
                                            name={name}
                                            required
                                            fullWidth
                                            id={name}
                                            label="Booking Price"
                                            error={Boolean(errors[name])}
                                            value={bookingPrice}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                        />
                                    )}
                                    control={control}
                                    name={'bookingPrice'}
                                    rules={{ required: { value: true, message: 'Booking price is required' }, pattern: { value: /^[0-9]+$/, message: 'Booking price must be a number' } }}
                                />*/}








                            </FormControl>
                        </Grid>

                        {bookingModeOfPayment === "Sales Partner Account" ?
                            <Grid mt={3} item xs={12}>
                                <Typography align={"center"}>
                                    Note: This Booking is made through Sales Partner’s account. Customer must take receiving from
                                    Sales Partner who is booking the plot.
                                </Typography>
                            </Grid> : ''
                        }
                            {bookingModeOfPayment.length > 0 && bookingModeOfPayment !== "Sales Partner Account" ?
                                <>
                                {/* Bank Name  */}
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Controller
                                            render={({ field: { name, value, onChange } }) => (
                                                <TextField
                                                    name={name}
                                                    required
                                                    fullWidth
                                                    id={name}
                                                    label="Bank Name"
                                                    error={Boolean(errors[name])}
                                                    value={value}
                                                    helperText={errors[name] ? String(errors[name]?.message) : ""}
                                                    onChange={(event) => {
                                                        onChange(event);
                                                        setBookingBankName(event.target.value);
                                                    }}
                                                />
                                            )}
                                            control={control}
                                            name={'bankName'}
                                            rules={{ required: { value: true, message: 'Bank name is required' } }}
                                        />

                                    </FormControl>
                                </Grid>

                                {/* Branch Name  */}
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Controller
                                            render={({ field: { name, value, onChange } }) => (
                                                <TextField
                                                    name={name}
                                                    required
                                                    fullWidth
                                                    id={name}
                                                    label="Branch Name"
                                                    error={Boolean(errors[name])}
                                                    value={value}
                                                    helperText={errors[name] ? String(errors[name]?.message) : ""}
                                                    onChange={(event) => {
                                                        onChange(event);
                                                        setBookingBankBranchName(event.target.value);
                                                    }}
                                                />
                                            )}
                                            control={control}
                                            name={'branchName'}
                                            rules={{ required: { value: true, message: 'Branch name is required' } }}
                                        />
                                    </FormControl>
                                </Grid>

                                {/* D.Slip, DD, PO No.  */}
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <Controller
                                            render={({ field: { name, value, onChange } }) => (
                                                <TextField
                                                    name={name}
                                                    required
                                                    fullWidth
                                                    id={name}
                                                    label="D.Slip/DD/PO No."
                                                    error={Boolean(errors[name])}
                                                    value={value}
                                                    helperText={errors[name] ? String(errors[name]?.message) : ""}
                                                    onChange={(event) => {
                                                        onChange(event);
                                                        setBookingDsDdPoNo(event.target.value);
                                                    }}
                                                />
                                            )}
                                            control={control}
                                            name={'bookingDsDdPoNo'}
                                            rules={{ required: { value: true, message: 'D.Slip/DD/PO No. is required' } }}
                                        />
                                    </FormControl>
                                </Grid>
                                </>
                                : ''
                            }


                    </Grid>

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        NEXT STEP - UPLOAD DOCUMENTS
                    </Button>
                </Box>
            </Box>

    );


  return bookingForm;
}