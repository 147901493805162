import * as React from "react";
import {createTheme} from "@mui/material/styles";
import Api from "../../helpers/api";
import Sidebar from "../common/Sidebar";
import BookingDataTable from "../datatables/BookingDataTable";
import UserDataTable from "../datatables/UserDataTable";

const theme = createTheme();


export default function Users() {
    const [users, setUsers] = React.useState([]);


    const api = new Api();

    React.useEffect(() => {
        api.getAllUsers().then((response) => {
            console.log(response);
            setUsers(response.data.data);
        })
    }, []);

    const handleApprove = (id: any, approve: any) => {
        api.approveUser(id, {approved: approve}).then((response) => {
            console.log(response);

            setUsers((prevUsers:any) => {
                    return prevUsers.map((user: any) =>
                        user.id === id ? {...user, approved: approve} : user
                    );
                }
            );
        })
    }

    const handleDelete = (userIndex: number) => {
        setUsers((prevUsers) =>
            prevUsers.filter((_, index) => index !== userIndex)
        );
    };

    return <Sidebar childComponent={<UserDataTable users={users} handleApprove={handleApprove} handleDelete={handleDelete} />} index={2} title={"All Users"} /> ;

}