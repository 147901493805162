import * as React from 'react';
import {useRef, useState} from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {createTheme} from '@mui/material/styles';
import Api from "../../../helpers/api";
import jwt_decode from "jwt-decode";
import {PrintBooking} from "../PrintBooking";
import {useReactToPrint} from "react-to-print";

const theme = createTheme();

const bucketName = process.env.REACT_APP_BUCKET_NAME;
export default function BookingFormStep3(props:any) {

    const booking = props.booking;

    const api = new Api();

    const jwtDecoded:any = localStorage.getItem("accessToken") !== null ? jwt_decode(localStorage.getItem(`accessToken`) as string) : null;

    // Use effect
    React.useEffect(() => {
        if(jwtDecoded) {

        }
    }, []);


    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#0960e6");

    const componentRef = useRef<HTMLInputElement>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    const bookingForm = (

            <Box
                sx={{
                    // marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >

                <Grid container textAlign={"center"} alignItems={"center"} spacing={2}>

                    <Grid item xs={12} sm={12}>
                        <Typography variant={"h4"} >
                            Application Form - {booking?.formNumber}
                        </Typography>
                    </Grid>

                </Grid>

                <Grid container spacing={2} mt={3}>

                    <Grid item={true} xs={12} sm={12}>
                    <Typography variant={"h5"} >
                        Booking Form - {booking?.formNumber} has been submitted successfully.
                    </Typography>

                        {/* Pass props to PrintBooking */}

                        <div style={{ display: "none" }}><PrintBooking customBookingId={booking?.id} ref={componentRef} /></div>

                        <Button variant={"contained"} onClick={handlePrint}>Print Form</Button>

                    </Grid>

                </Grid>

            </Box>

    );


  return bookingForm;
}