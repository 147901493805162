import * as React from 'react';
import {useRef, useState} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import Moment from 'moment';

import {
    Chip,
    Divider,
    FormControl,
    FormHelperText,
    Input,
    InputLabel,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import Api from "../../helpers/api";
import Sidebar from "../common/Sidebar";
import Copyright from "../common/Copyright";
import jwt_decode from "jwt-decode";
import {useParams} from "react-router-dom";
import {useReactToPrint} from "react-to-print";
import {LocalPhone, MailOutline} from "@mui/icons-material";
import Barcode from "react-barcode";

const theme = createTheme();

const bucketName = process.env.REACT_APP_BUCKET_NAME;

<style type="text/css" media="print">{"\
  @page {\ size: landscape;\ }\
"}</style>

export const PrintBookingReceipt = React.forwardRef((props, ref) => {

    

    const {bookingId} = useParams();

    const api = new Api();

    const jwtDecoded:any = localStorage.getItem("accessToken") !== null ? jwt_decode(localStorage.getItem(`accessToken`) as string) : null;

    const [bookingApplicationFormNumber, setBookingApplicationFormNumber] = React.useState('001150');
    const [bookingMembershipId, setBookingMembershipId] = React.useState('001150');
    const [bookingStatus, setBookingStatus] = React.useState('');
    const [bookingDate, setBookingDate] = React.useState<Date | null>(new Date());
    const [bookingDealCode, setBookingDealCode] = React.useState('');
    const [bookingProjectName, setBookingProjectName] = React.useState('');

    // Customer Details
    const [applicantFullName, setApplicantFullName] = React.useState('');
    const [applicantCnic, setApplicantCnic] = React.useState('');

    const [applicantDob, setApplicantDob] = React.useState<Date | null>(new Date());
    const [applicantEmail, setApplicantEmail] = React.useState('');
    const [applicantContactNumber, setApplicantContactNumber] = React.useState('');

    const [applicantMailingAddress, setApplicantMailingAddress] = React.useState('');

    const [applicantPermanentAddress, setApplicantPermanentAddress] = React.useState('');

    const [applicantImageUrl, setApplicantImageUrl] = React.useState('');


    // Customer - Next of Kin Details
    const [nextOfKinFullName, setNextOfKinFullName] = React.useState('');
    const [nextOfKinCnic, setNextOfKinCnic] = React.useState('');

    const [nextOfKinRelationship, setNextOfKinRelationship] = React.useState('');
    const [nextOfKinEmail, setNextOfKinEmail] = React.useState('');
    const [nextOfKinContactNumber, setNextOfKinContactNumber] = React.useState('');

    const [nextOfKinMailingAddress, setNextOfKinMailingAddress] = React.useState('');
    const [nextOfKinPermanentAddress, setNextOfKinPermanentAddress] = React.useState('');


    // Booking Details
    const [bookingCategory, setBookingCategory] = React.useState('');
    const [bookingSize, setBookingSize] = React.useState('');

    const [realtorCompanyName, setRealtorCompanyName] = React.useState('');
    const [realtorContactNumber, setRealtorContactNumber] = React.useState('');
    const [realtorRepresentativeName, setRealtorRepresentativeName] = React.useState('');
    const [realtorRepresentativeCnic, setRealtorRepresentativeCnic] = React.useState('');

    const [realtorManagerName, setRealtorManagerName] = React.useState('');
    const [realtorManagerCnic, setRealtorManagerCnic] = React.useState('');


    // Payment Details
    const [bookingModeOfPayment, setBookingModeOfPayment] = React.useState('');
    const [bookingPrice, setBookingPrice] = React.useState('');

    const [bookingBankName, setBookingBankName] = React.useState('');
    const [bookingBankBranchName, setBookingBankBranchName] = React.useState('');

    const [bookingDsDdPoNo, setBookingDsDdPoNo] = React.useState('');

    const handleBookingSizeChange = (event: SelectChangeEvent) => {
        const currentBookingSize = event.target.value as string;
        setBookingSize(currentBookingSize as string);

        if(currentBookingSize === '7 Marla') {
            setBookingPrice('7700000');
        }
        else if(currentBookingSize === '10 Marla') {
            setBookingPrice('10000000');
        }
        else if(currentBookingSize === '1 Kanal') {
            setBookingPrice('18000000');
        }
        else if(currentBookingSize === '2 Kanal') {
            setBookingPrice('32000000');
        }

    }



    // Use effect
    React.useEffect(() => {
        if(jwtDecoded) {
            api.getBookingById(bookingId)
                .then((response) => {
                    setBookingApplicationFormNumber(response.data.data.formNumber);
                    setBookingMembershipId(response.data.data.memberId);
                    setBookingStatus(response.data.data.bookingStatus);
                    setBookingDate(response.data.data.bookingDate);
                    setBookingDealCode(response.data.data.dealCode);
                    setBookingProjectName(response.data.data.projectName);

                    setApplicantFullName(response.data.data.applicant.fullName);
                    setApplicantCnic(response.data.data.applicant.cnic.number);
                    setApplicantDob(response.data.data.applicant.dateOfBirth);
                    setApplicantEmail(response.data.data.applicant.email);
                    setApplicantContactNumber(response.data.data.applicant.phone);
                    setApplicantMailingAddress(response.data.data.applicant.mailingAddress);
                    setApplicantPermanentAddress(response.data.data.applicant.permanentAddress);
                    setApplicantImageUrl(`https://${bucketName}.s3.amazonaws.com/${response.data.data.applicant.image}`);

                    setNextOfKinFullName(response.data.data.nextOfKin.fullName);
                    setNextOfKinCnic(response.data.data.nextOfKin.cnic.number);
                    setNextOfKinRelationship(response.data.data.nextOfKin.relationship);
                    setNextOfKinEmail(response.data.data.nextOfKin.email);
                    setNextOfKinContactNumber(response.data.data.nextOfKin.phone);
                    setNextOfKinMailingAddress(response.data.data.nextOfKin.mailingAddress);
                    setNextOfKinPermanentAddress(response.data.data.nextOfKin.permanentAddress);


                    setBookingCategory(response.data.data.category);
                    setBookingSize(response.data.data.size);

                    setRealtorCompanyName(response.data.data.realtor.companyName);
                    setRealtorRepresentativeName(response.data.data.realtor.firstName + ' ' + response.data.data.realtor.lastName);
                    setRealtorRepresentativeCnic(response.data.data.realtor.cnic.number);
                    setRealtorContactNumber(response.data.data.realtor.phoneNumbers[0]);

                    setRealtorManagerName(response.data.data.realtor.manager?.fullName);
                    setRealtorManagerCnic(response.data.data.realtor.manager?.cnic?.number);

                    setBookingPrice(response.data.data.bookingPrice);
                    setBookingModeOfPayment(response.data.data.modeOfPayment);
                    setBookingBankName(response.data.data.bankName);
                    setBookingBankBranchName(response.data.data.bankBranch);
                    setBookingDsDdPoNo(response.data.data.paymentIdentifier);



                }).catch((error) => {
                console.log(error);
            });

        }

    }, []);

    const [selectedApplicantImage, setSelectedApplicantImage] = React.useState<File | null>(null);
    const [selectedApplicantImageName, setSelectedApplicantImageName] = React.useState<string | null>(null);

    const [applicantCnicImage, setApplicantCnicImage] = React.useState();
    const [nextOfKinCnicImage, setNextOfKinCnicImage] = React.useState();
    const [poImage, setPoImage] =  React.useState();

    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#0960e6");
    const onStatusChange = (id: any, status: any) => {

        api.updateBookingStatus(id, {bookingStatus: status}).then((response) => {
                console.log(response);
                // updateBookingData(response.data.data);
            }
        ).catch((error) => {
                console.log(error);
            }
        )

    }

/*    const componentRef = useRef<HTMLInputElement>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });*/

/*    const componentRef = useRef<HTMLInputElement>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });*/


    // @ts-ignore
    const bookingForm = (

     <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xl">
            <CssBaseline />
            <Box ref={ref}
                 sx={{
                     paddingX: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >

                <Box component="form" aria-disabled={true} noValidate  sx={{ mt: 0.5 }}>

                    <Grid mt={20} container spacing={2}>

                        <Grid item xs={12} sm={8}>
                            <Barcode value={bookingApplicationFormNumber}
                                     width={2}
                                     height={44}
                                     format= "CODE128"
                                     displayValue= {false}
                                     font= "monospace"
                                     textAlign= "center"
                                     textPosition= "bottom"
                                     textMargin= {1}
                                     fontSize= {13}
                                     background= "#ffffff"
                                     lineColor= "#000000"
                            />
                        </Grid>

                        {/* Receipt Date  */}
                        <Grid item xs={12} sm={4} mt={2}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={2.5} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Date:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9.5}>
                                    <TextField
                                        fullWidth
                                        value={Moment(bookingDate).format('DD/MM/YYYY')}
                                        variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                    <Grid container textAlign={"center"} alignItems={"center"} mt={6} mb={3}>

                        <Grid item xs={12} sm={12}>
                            <Typography variant={"h4"} style={{fontWeight: 'bold'}} >
                                Booking Receipt
                            </Typography>
                        </Grid>
                    </Grid>


                    <Grid mt={1} container spacing={2}>
                    {/* Receipt */}

                        {/* Receipt Application Form Number*/}

                        <Grid item xs={12} sm={8}>
                            <Grid container spacing={1}  pt={2}>
                                <Grid item xs={12} sm={2.8} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Application no:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9.2}>
                                    <TextField
                                        fullWidth
                                        value={bookingApplicationFormNumber}
                                        id="input-with-sx" variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                            <Grid container spacing={1}  pt={2}>
                                <Grid item xs={12} sm={6} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Membership ID:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        value={bookingMembershipId}
                                        id="input-with-sx" variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Receipt Project Name / Sector  */}
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={2} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Project:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                    <TextField
                                        fullWidth
                                        value={bookingProjectName} variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>


                        {/* Receipt Category  */}
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={2.6} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Category:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9.4}>
                                    <TextField
                                        fullWidth
                                        value={bookingCategory} variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Receipt Size  */}
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={1.5} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Size:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={10.5}>
                                    <TextField
                                        fullWidth
                                        value={bookingSize} variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>

                        {bookingModeOfPayment === "Sales Partner Account" ?

                            <>

                        {/* Receipt Booking Price - Sales Partner  */}
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3.8} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Booking Price:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={8.2}>
                                    <TextField
                                        fullWidth
                                        value={bookingPrice}
                                        variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>

                            </>

                            :

                            <>

                        {/* Receipt Booking Price - NOT Sales Partner  */}
                        <Grid item xs={12} sm={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3.4} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Paid Amount:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={8.6}>
                                    <TextField
                                        fullWidth
                                        value={bookingPrice}
                                        variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>

                        </>
                        }

                        {/* Customer Full Name  */}
                        <Grid item xs={12} sm={8}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3.3} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Customer Name:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={8.7}>
                                    <TextField
                                        fullWidth
                                        value={applicantFullName}
                                        variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>

                        {/* Customer CNIC  */}
                        <Grid item xs={12} sm={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={3} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>CNIC:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9}>
                                    <TextField
                                        fullWidth
                                        value={applicantCnic}
                                        id="input-with-sx" variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>



                        {bookingModeOfPayment === "Sales Partner Account" ?

                            <>

                                {/* Sales Partner Company  */}
                                <Grid item xs={12} sm={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={3} mt={0.5}>
                                            <InputLabel style={{color:"#000"}}>Sales Partner Company:</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} sm={9}>
                                            <TextField
                                                fullWidth
                                                value={realtorCompanyName}
                                                id="input-with-sx" variant="standard" />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid mt={3} item xs={12}>
                                    <Typography align={"center"}>
                                        Note: This Booking is made through Sales Partner’s account. Customer must take receiving from
                                        Sales Partner who is booking the plot.
                                    </Typography>
                                </Grid>

                            </>


                            :
                            <>

                                {/* Receipt D.Slip, DD, PO No.  */}
                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={4.4} mt={0.5}>
                                            <InputLabel style={{color:"#000"}}>D.Slip/DD/PO No:</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} sm={7.6}>
                                            <TextField
                                                fullWidth
                                                value={bookingDsDdPoNo}
                                                variant="standard" />
                                        </Grid>
                                    </Grid>
                                </Grid>


                                {/* Bank Name  */}
                                <Grid item xs={12} sm={6}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={3.2} mt={0.5}>
                                            <InputLabel style={{color:"#000"}}>Bank name:</InputLabel>
                                        </Grid>
                                        <Grid item xs={12} sm={8.8}>
                                            <TextField
                                                fullWidth
                                                value={bookingBankName}
                                                id="input-with-sx" variant="standard" />
                                        </Grid>
                                    </Grid>
                                </Grid>


                            </>
                        }






                        {/* Empty Space  */}
                        <Grid mt={3} item xs={12}>
                        </Grid>




                        <Grid item xs={0.5}></Grid>

                        {/* Booking Rep - Title  */}
                        <Grid item xs={12} sm={5}>
                            <Typography align={"center"}>
                                <b>Booking Rep</b>
                            </Typography>
                        </Grid>

                        <Grid item xs={1}></Grid>

                        {/* Booking Manager - Title */}
                        <Grid item xs={12} sm={5}>
                            <Typography align={"center"}>
                                <b>Booking Manager</b>
                            </Typography>
                        </Grid>

                        <Grid item xs={0.5}></Grid>



                        <Grid item xs={0.5}></Grid>

                        {/* Booking Rep - Name  */}
                        <Grid item xs={12} sm={5}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={2.5} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Name:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9.5}>
                                    <TextField
                                        fullWidth
                                        value={realtorRepresentativeName}
                                        variant="standard" />
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid item xs={1}></Grid>

                        {/* Booking Manager - Name */}
                        <Grid item xs={12} sm={5}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={2.5} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Name:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9.5}>
                                    <TextField
                                        fullWidth
                                        value={realtorManagerName}
                                        variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={0.5}></Grid>


                        <Grid item xs={0.5}></Grid>

                        {/* Booking Rep - CNIC  */}
                        <Grid item xs={12} sm={5}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={2.5} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>CNIC:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9.5}>
                                    <TextField
                                        fullWidth
                                        value={realtorRepresentativeCnic}
                                        variant="standard" />
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid item xs={1}></Grid>

                        {/* Booking Manager - CNIC  */}
                        <Grid item xs={12} sm={5}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={2.5} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>CNIC:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9.5}>
                                    <TextField
                                        fullWidth
                                        value={realtorManagerCnic}
                                        variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={0.5}></Grid>




                        <Grid item xs={0.5}></Grid>

                        {/* Booking Rep - Sign  */}
                        <Grid item xs={12} sm={5} mt={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={2.5} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Sign:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9.5}>
                                    <TextField
                                        fullWidth
                                        // value={realtorRepresentativeCnic}
                                        variant="standard" />
                                </Grid>
                            </Grid>

                        </Grid>

                        <Grid item xs={1}></Grid>

                        {/* Booking Manager - Sign  */}
                        <Grid item xs={12} sm={5} mt={4}>
                            <Grid container spacing={1}>
                                <Grid item xs={12} sm={2.5} mt={0.5}>
                                    <InputLabel style={{color:"#000"}}>Sign:</InputLabel>
                                </Grid>
                                <Grid item xs={12} sm={9.5}>
                                    <TextField
                                        fullWidth
                                        // value={applicantCnic}
                                        variant="standard" />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={0.5}></Grid>



                        <Grid mt={3} item xs={12}>
                            <Typography sx={{fontWeight:'bolder'}}>
                                *Approved by CDA as Al-Hamra Avenue
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography sx={{fontWeight:'bolder'}}>
                                *This booking Must be verified on www.propertiesbag.com/verification
                            </Typography>
                        </Grid>



                    </Grid>
                </Box>


                {/*<Copyright />*/}



            </Box>
        </Container>
    </ThemeProvider>

    );




  return <Sidebar childComponent={bookingForm} index={0}  title={"New Booking"} />;
});