import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Api from "../../helpers/api";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {Link, Router, useNavigate} from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function BookingDataTable(props: any) {

    let navigate = useNavigate();

    const rows = props.bookings;
    const updateBookingData = props.updateBookingData;
    const api = new Api();
    const jwtDecoded = props.jwtDecoded;


    const onStatusChange = (id: any, status: any) => {

        api.updateBookingStatus(id, {bookingStatus: status}).then((response) => {
            console.log(response);
            // updateBookingData(response.data.data);
        }
        ).catch((error) => {
            console.log(error);
        }
        )

    }





    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Project</StyledTableCell>
                        <StyledTableCell>Deal Code</StyledTableCell>
                        <StyledTableCell>Category</StyledTableCell>
                        <StyledTableCell>Size</StyledTableCell>
                        <StyledTableCell>Price</StyledTableCell>


                        {(
                            jwtDecoded.roles.includes("ADMIN")
                            || jwtDecoded.roles.includes("ACCOUNT_MANAGER")
                            || jwtDecoded.roles.includes("CEO")
                            || jwtDecoded.roles.includes("SALES_MANAGER")
                            ) &&
                            <StyledTableCell>Booking Officer</StyledTableCell>
                        }

                        {(
                                jwtDecoded.roles.includes("ADMIN")
                                || jwtDecoded.roles.includes("ACCOUNT_MANAGER")
                                || jwtDecoded.roles.includes("CEO")
                            ) &&
                            <StyledTableCell>Manager</StyledTableCell>
                        }

                        <StyledTableCell>Status</StyledTableCell>
                        <StyledTableCell></StyledTableCell>

                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.map((row:any) => (
                        <StyledTableRow key={row.id}>
                            <StyledTableCell component="th" scope="row">
                                {row.projectName}
                            </StyledTableCell>
                            <StyledTableCell>{row.dealCode}</StyledTableCell>
                            <StyledTableCell>{row.category}</StyledTableCell>
                            <StyledTableCell>{row.size}</StyledTableCell>
                            <StyledTableCell>{row.bookingPrice}</StyledTableCell>

                            {(
                                    jwtDecoded.roles.includes("ADMIN")
                                    || jwtDecoded.roles.includes("ACCOUNT_MANAGER")
                                    || jwtDecoded.roles.includes("CEO")
                                    || jwtDecoded.roles.includes("SALES_MANAGER")
                                ) &&
                                <StyledTableCell>{row.realtor?.firstName} {row.realtor?.lastName}</StyledTableCell>
                            }

                            {(
                                    jwtDecoded.roles.includes("ADMIN")
                                    || jwtDecoded.roles.includes("ACCOUNT_MANAGER")
                                    || jwtDecoded.roles.includes("CEO")
                                ) &&
                                <StyledTableCell>{row.realtor?.manager?.firstName} {row.realtor?.manager?.lastName}</StyledTableCell>

                            }

                                {/*{(!jwtDecoded.roles.includes("ADMIN") && !jwtDecoded.roles.includes("ACCOUNT_MANAGER")) &&*/}
                                    <StyledTableCell>{row.bookingStatus}</StyledTableCell>
                                {/*}*/}

                                { false && (jwtDecoded.roles.includes("ADMIN") || jwtDecoded.roles.includes("ACCOUNT_MANAGER")) &&
                                    <>
                                    <StyledTableCell>

                                    <Grid container>
                                    <Grid item md={8}>
                                    <FormControl fullWidth>
                                    <Select
                                    fullWidth
                                    size={"small"}
                                    id="bookingStatus"
                                    value={row.bookingStatus}
                                    onChange={(newValue) => {
                                    onStatusChange(row.id, newValue.target.value);
                                }}
                                    >
                                    <MenuItem value={"PENDING"}>PENDING</MenuItem>
                                    <MenuItem value={"PARTIALLY_CONFIRMED"}>PARTIALLY CONFIRMED</MenuItem>
                                    <MenuItem value={"FULLY_CONFIRMED"}>FULLY CONFIRMED</MenuItem>
                                    <MenuItem value={"RECEIPT_ISSUED"}>RECEIPT_ISSUED</MenuItem>
                                    </Select>

                                    </FormControl>
                                    </Grid>
                                    </Grid>
                                    </StyledTableCell>

                                    </>
                                }

                            <StyledTableCell>

                                <Button variant={"contained"} onClick={() => navigate(`/bookings/${row.id}`)}>View Details</Button>


                            </StyledTableCell>


                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}