import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Api from "../../helpers/api";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import Switch from "@mui/material/Switch";
import UserDeleteAlertDialog from "../Dialogues/UserDeleteAlertDialog";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function UserDataTable(props: any) {

    const rows = props.users;
    let navigate = useNavigate();
    const handleApprove = props.handleApprove;
    const handleDelete = props.handleDelete;

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Name</StyledTableCell>
                        {/*<StyledTableCell>Last Name</StyledTableCell>*/}
                        <StyledTableCell>Email</StyledTableCell>
                        {/*<StyledTableCell>CNIC</StyledTableCell>*/}
                        <StyledTableCell>Phone</StyledTableCell>
                        <StyledTableCell>Company</StyledTableCell>
                        <StyledTableCell>Role & Designation</StyledTableCell>
                        {/*<StyledTableCell>Designation</StyledTableCell>*/}
                        <StyledTableCell>Manager</StyledTableCell>
                        <StyledTableCell>Actions</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows?.map((row:any, rowIndex:any) => (
                        <StyledTableRow key={row.id}>
                            <StyledTableCell component="th" scope="row">
                                {row.fullName}
                            </StyledTableCell>
                            {/*<StyledTableCell>{row.lastName}</StyledTableCell>*/}
                            <StyledTableCell>{row.username}</StyledTableCell>
                            {/*<StyledTableCell>{row?.cnic?.number}</StyledTableCell>*/}
                            <StyledTableCell>{row.phoneNumbers[0]}</StyledTableCell>
                            <StyledTableCell>{row.companyName}</StyledTableCell>
                            <StyledTableCell>{row.roles[0]}<br />{row.designation}</StyledTableCell>
                            {/*<StyledTableCell>{row.designation}</StyledTableCell>*/}
                            <StyledTableCell>{row.manager?.fullName}</StyledTableCell>
                            <StyledTableCell>
                                <Button variant={"contained"} onClick={() => navigate(`/users/${row.id}`)}>Edit</Button>
                                <UserDeleteAlertDialog userId={row.id} handleDelete={handleDelete} userIndex={rowIndex} />
                                <Switch
                                    checked={row.approved}
                                    onChange={() => handleApprove(row.id, !row.approved)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}