import * as React from 'react';
import {ChangeEvent, useRef, useState} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {
    Chip,
    Divider,
    FormControl,
    FormHelperText,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableRow
} from "@mui/material";
import Api from "../../helpers/api";
import Sidebar from "../common/Sidebar";
import Copyright from "../common/Copyright";
import jwt_decode from "jwt-decode";
import {BounceLoader, ClipLoader} from "react-spinners";
import {PhotoCamera} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {LoadingButton} from "@mui/lab";
import {useNavigate, useParams} from "react-router-dom";
import {useReactToPrint} from "react-to-print";
import {PrintBooking} from "./PrintBooking";
import {PrintBookingReceipt} from "./PrintBookingReceipt";

const theme = createTheme();

const bucketName = process.env.REACT_APP_BUCKET_NAME;

export default function EditBooking() {

    

    const {bookingId} = useParams();

    let navigate = useNavigate();

    const api = new Api();

    const jwtDecoded:any = localStorage.getItem("accessToken") !== null ? jwt_decode(localStorage.getItem(`accessToken`) as string) : null;

    // const [bookingId, setBookingId] = useState("");
    const [bookingApplicationFormNumber, setBookingApplicationFormNumber] = React.useState('001150');
    const [bookingStatus, setBookingStatus] = React.useState('');
    const [bookingDate, setBookingDate] = React.useState<Date | null>(new Date());
    const [bookingDealCode, setBookingDealCode] = React.useState('');
    const [bookingProjectName, setBookingProjectName] = React.useState('');

    // Customer Details
    const [applicantFullName, setApplicantFullName] = React.useState('');
    const [applicantCnic, setApplicantCnic] = React.useState('');

    const [applicantDob, setApplicantDob] = React.useState<Date | null>(new Date());
    const [applicantEmail, setApplicantEmail] = React.useState('');
    const [applicantContactNumber, setApplicantContactNumber] = React.useState('');

    const [applicantMailingAddress, setApplicantMailingAddress] = React.useState('');

    const [applicantPermanentAddress, setApplicantPermanentAddress] = React.useState('');

    // Customer - Next of Kin Details
    const [nextOfKinFullName, setNextOfKinFullName] = React.useState('');
    const [nextOfKinCnic, setNextOfKinCnic] = React.useState('');

    const [nextOfKinRelationship, setNextOfKinRelationship] = React.useState('');
    const [nextOfKinEmail, setNextOfKinEmail] = React.useState('');
    const [nextOfKinContactNumber, setNextOfKinContactNumber] = React.useState('');

    const [nextOfKinMailingAddress, setNextOfKinMailingAddress] = React.useState('');
    const [nextOfKinPermanentAddress, setNextOfKinPermanentAddress] = React.useState('');


    // Booking Details
    const [bookingCategory, setBookingCategory] = React.useState('');
    const [bookingSize, setBookingSize] = React.useState('');

    const [realtorCompanyName, setRealtorCompanyName] = React.useState('');
    const [realtorContactNumber, setRealtorContactNumber] = React.useState('');
    const [realtorRepresentativeName, setRealtorRepresentativeName] = React.useState('');
    const [realtorRepresentativeCnic, setRealtorRepresentativeCnic] = React.useState('');


    // Payment Details
    const [bookingModeOfPayment, setBookingModeOfPayment] = React.useState('');
    const [bookingPrice, setBookingPrice] = React.useState('');

    const [bookingBankName, setBookingBankName] = React.useState('');
    const [bookingBankBranchName, setBookingBankBranchName] = React.useState('');

    const [bookingDsDdPoNo, setBookingDsDdPoNo] = React.useState('');

    const [receiptIssued, setReceiptIssued] = React.useState(false);

    const handleBookingSizeChange = (event: SelectChangeEvent) => {
        const currentBookingSize = event.target.value as string;
        setBookingSize(currentBookingSize as string);

        if(currentBookingSize === '7 Marla') {
            setBookingPrice('7700000');
        }
        else if(currentBookingSize === '10 Marla') {
            setBookingPrice('10000000');
        }
        else if(currentBookingSize === '1 Kanal') {
            setBookingPrice('18000000');
        }
        else if(currentBookingSize === '2 Kanal') {
            setBookingPrice('32000000');
        }

    }


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        let applicant = {
            fullName: applicantFullName,
            cnic: applicantCnic,
            dateOfBirth: applicantDob,
            email: applicantEmail,
            phone: applicantContactNumber,
            mailingAddress: applicantMailingAddress,
            permanentAddress: applicantPermanentAddress
        };

        let nextOfKin = {
            fullName: nextOfKinFullName,
            cnic: nextOfKinCnic,
            relationship: nextOfKinRelationship,
            email: nextOfKinEmail,
            phone: nextOfKinContactNumber,
            mailingAddress: nextOfKinMailingAddress,
            permanentAddress: nextOfKinPermanentAddress
        };

        let booking = {
            projectName: "Affinity Enclave",
            formNumber: bookingApplicationFormNumber,
            bookingDate: bookingDate,
            dealCode: bookingDealCode,
            category: bookingCategory,
            size: bookingSize,
            bookingPrice: bookingPrice,
            modeOfPayment: bookingModeOfPayment,
            bankName: bookingBankName,
            bankBranch: bookingBankBranchName,
            paymentIdentifier: bookingDsDdPoNo,
/*            realtorCompany: realtorCompanyName,
            realtorPhone: realtorContactNumber,
            realtorRepresentative: realtorRepresentativeName,
            realtorCnic: realtorRepresentativeCnic,*/
            realtor: {
                id: jwtDecoded.uid
            },
            applicant: applicant,
            nextOfKin: nextOfKin
        }

        api.createNewBooking(booking).then((response) => {
            console.log(response);
        }).catch((error) => {
            console.log(error);
        });

    };

    // Use effect
    React.useEffect(() => {
        if(jwtDecoded) {
            api.getBookingById(bookingId)
                .then((response) => {

                    setReceiptIssued(response.data.data.receiptIssued);

                    setBookingApplicationFormNumber(response.data.data.formNumber);
                    setBookingStatus(response.data.data.bookingStatus);
                    setBookingDate(response.data.data.bookingDate);
                    setBookingDealCode(response.data.data.dealCode);
                    setBookingProjectName(response.data.data.projectName);

                    setApplicantFullName(response.data.data.applicant.fullName);
                    setApplicantCnic(response.data.data.applicant.cnic.number);
                    setApplicantDob(response.data.data.applicant.dateOfBirth);
                    setApplicantEmail(response.data.data.applicant.email);
                    setApplicantContactNumber(response.data.data.applicant.phone);
                    setApplicantMailingAddress(response.data.data.applicant.mailingAddress);
                    setApplicantPermanentAddress(response.data.data.applicant.permanentAddress);

                    setNextOfKinFullName(response.data.data.nextOfKin.fullName);
                    setNextOfKinCnic(response.data.data.nextOfKin.cnic.number);
                    setNextOfKinRelationship(response.data.data.nextOfKin.relationship);
                    setNextOfKinEmail(response.data.data.nextOfKin.email);
                    setNextOfKinContactNumber(response.data.data.nextOfKin.phone);
                    setNextOfKinMailingAddress(response.data.data.nextOfKin.mailingAddress);
                    setNextOfKinPermanentAddress(response.data.data.nextOfKin.permanentAddress);


                    setBookingCategory(response.data.data.category);
                    setBookingSize(response.data.data.size);

                    setRealtorCompanyName(response.data.data.realtor?.companyName);
                    setRealtorRepresentativeName(response.data.data.realtor?.firstName + ' ' + response.data.data.realtor.lastName);
                    setRealtorRepresentativeCnic(response.data.data.realtor?.cnic?.number);
                    setRealtorContactNumber(response.data.data.realtor?.phoneNumbers[0]);

                    setBookingPrice(response.data.data.bookingPrice);
                    setBookingModeOfPayment(response.data.data.modeOfPayment);
                    setBookingBankName(response.data.data.bankName);
                    setBookingBankBranchName(response.data.data.bankBranch);
                    setBookingDsDdPoNo(response.data.data.paymentIdentifier);

                    setApplicantImageUrl(`https://${bucketName}.s3.amazonaws.com/${response.data.data.applicant.image}`);
                    setApplicantCnicFrontImageUrl(`https://${bucketName}.s3.amazonaws.com/${response.data.data.applicant.cnic.frontSide}`);
                    setApplicantCnicBackImageUrl(`https://${bucketName}.s3.amazonaws.com/${response.data.data.applicant.cnic.backSide}`);

                    setNextOfKinImageUrl(`https://${bucketName}.s3.amazonaws.com/${response.data.data.nextOfKin.cnic.image}`);
                    setNextOfKinCnicFrontImageUrl(`https://${bucketName}.s3.amazonaws.com/${response.data.data.nextOfKin.cnic.frontSide}`);
                    setNextOfKinCnicBackImageUrl(`https://${bucketName}.s3.amazonaws.com/${response.data.data.nextOfKin.cnic.backSide}`);

                    setPaymentIdentifierImageUrl(`https://${bucketName}.s3.amazonaws.com/${response.data.data.paymentIdentifierImage}`);




                }).catch((error) => {
                console.log(error);
            });

        }

    }, []);


    const onStatusChange = (id: any, status: any) => {

        api.updateBookingStatus(id, {bookingStatus: status}).then((response) => {
                console.log(response);
                // updateBookingData(response.data.data);
            window.location.reload();
            }
        ).catch((error) => {
                console.log(error);
            }
        )

    }



    const [applicantImageUrl, setApplicantImageUrl] = React.useState('');
    const [applicantCnicFrontImageUrl, setApplicantCnicFrontImageUrl] = React.useState('');
    const [applicantCnicBackImageUrl, setApplicantCnicBackImageUrl] = React.useState('');
    const [nextOfKinImageUrl, setNextOfKinImageUrl] = React.useState('');
    const [nextOfKinCnicFrontImageUrl, setNextOfKinCnicFrontImageUrl] = React.useState('');
    const [nextOfKinCnicBackImageUrl, setNextOfKinCnicBackImageUrl] = React.useState('');
    const [paymentIdentifierImageUrl, setPaymentIdentifierImageUrl] = React.useState('');

    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#0960e6");













    const onPrintingBookingReceipt = (id: any, status: any) => {

        api.updateBookingStatus(id, {bookingStatus: status}).then((response) => {
                console.log(response);
                handlePrint();
            }
        ).catch((error) => {
                console.log(error);
            }
        )

    }


    const componentRef = useRef<HTMLInputElement>(null);
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const componentRef1 = useRef<HTMLInputElement>(null);
    const handlePrint1 = useReactToPrint({
        content: () => componentRef1.current,
    });


    const bookingForm = (

     <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    // alignItems: 'center',
                }}
            >

                {/*<Button variant={"contained"} onClick={() => navigate("/bookings")}>Go Back</Button>*/}

                <div style={{ display: "none" }}><PrintBookingReceipt ref={componentRef} /></div>
                <div style={{ display: "none" }}><PrintBooking customBookingId={bookingId} ref={componentRef1} /></div>

                <Grid mt={1} mb={2} container spacing={2}>

                    <Grid item xs={12} sm={4}>
                        {bookingStatus === 'FULLY_CONFIRMED' && !receiptIssued && jwtDecoded.roles.includes('ACCOUNT_MANAGER') &&
                            <Button variant={"contained"} color={'success'} onClick={() => onPrintingBookingReceipt(bookingId, "RECEIPT_ISSUED")}>Print Receipt</Button>
                        }
                    </Grid>

                    <Grid item xs={12} sm={4}>
                    </Grid>

                    <Grid item xs={12} sm={4} textAlign={"right"} alignItems={"right"}>
                        {<Button variant={"contained"} onClick={handlePrint1}>Print Form</Button>}

                    </Grid>
                </Grid>

                <Box component="form" aria-disabled={true} noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        {/*Application Form Number*/}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    disabled={true}
                                    name="applicationFormNumber"
                                    required
                                    value={bookingApplicationFormNumber}
                                    fullWidth
                                    id="applicationFormNumber"
                                    label="Application Form Number"
                                />
                            </FormControl>
                        </Grid>

                        {/*Booking Status */}
                        <Grid item xs={12} sm={6}>

                                    <FormControl fullWidth>
                                        <InputLabel id="bookingStatusLabel">Booking Status</InputLabel>
                                        <Select
                                            fullWidth
                                            // disabled={!jwtDecoded.roles.includes("ACCOUNT_MANAGER")}
                                            disabled={receiptIssued || !jwtDecoded.roles.includes("ACCOUNT_MANAGER")}
                                            labelId="bookingStatusLabel"
                                            id="bookingStatus"
                                            value={bookingStatus}
                                            label={"Booking Status"}
                                            onChange={(newValue) => {
                                                onStatusChange(bookingId, newValue.target.value);
                                            }}
                                        >
                                            <MenuItem value={"PENDING"}>PENDING</MenuItem>
                                            <MenuItem value={"PARTIALLY_CONFIRMED"}>PARTIALLY CONFIRMED</MenuItem>
                                            <MenuItem value={"FULLY_CONFIRMED"}>FULLY CONFIRMED</MenuItem>
                                            {receiptIssued &&  <MenuItem value={"RECEIPT_ISSUED"}>RECEIPT ISSUED</MenuItem>}
                                        </Select>

                                    </FormControl>
                        </Grid>

                        {/* Date */}
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label="Date"
                                    disabled={true}
                                    value={bookingDate}
                                    inputFormat={"DD/MM/YYYY"}
                                    onChange={(newValue) => {
                                        // setBookingDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                            </FormControl>
                        </Grid>

                        {/* Project Name */}
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <TextField
                                    disabled={true}
                                    name="bookingProjectName"
                                    required
                                    value={bookingProjectName}
                                    fullWidth
                                    id="bookingProjectName"
                                    label="Project Name"
                                />
                            </FormControl>
                        </Grid>

                        {/* Deal Code */}
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <TextField
                                    disabled={true}
                                    name="bookingDealCode"
                                    required
                                    value={bookingDealCode}
                                    fullWidth
                                    id="bookingDealCode"
                                    label="Deal Code"
                                />
                            </FormControl>
                        </Grid>

                        {/* Customer Full Name  */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    name="customerFullName"
                                    required
                                    fullWidth
                                    disabled={true}
                                    id="customerFullName"
                                    label="Customer Name"
                                    value={applicantFullName}
                                    onChange={(event) => {
                                        setApplicantFullName(event.target.value);
                                    }
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {/* Customer CNIC  */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    name="customerCnic"
                                    required
                                    disabled={true}
                                    fullWidth
                                    id="customerCnic"
                                    label="CNIC"
                                    value={applicantCnic}
                                    onChange={(event) => {
                                        setApplicantCnic(event.target.value);
                                    }
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {/* Customer Date of Birth */}
                        <Grid item xs={12} sm={4}>
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                    label="Date of Birth"
                                    disabled={true}
                                    inputFormat={"DD/MM/YYYY"}
                                    value={applicantDob}
                                    onChange={(newValue) => {
                                        // setApplicantDob(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>

                        {/* Customer Email  */}
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <TextField
                                    name="customerEmail"
                                    fullWidth
                                    disabled={true}
                                    id="customerEmail"
                                    label="Email"
                                    autoComplete={"email"}
                                    value={applicantEmail}
                                    onChange={(event) => {
                                        setApplicantEmail(event.target.value);
                                    }
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {/* Customer Cell Number  */}
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <TextField
                                    name="customerCellNumber"
                                    required
                                    disabled={true}
                                    fullWidth
                                    id="customerCellNumber"
                                    label="Cell Number"
                                    autoComplete={"tel"}
                                    value={applicantContactNumber}
                                    onChange={(event) => {
                                        setApplicantContactNumber(event.target.value);
                                    }
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {/* Customer Mailing Address  */}
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <TextField
                                    name="customerMailAddress"
                                    required
                                    fullWidth
                                    disabled={true}
                                    id="customerMailAddress"
                                    label="Mailing Address"
                                    autoComplete={"mailing-address"}
                                    value={applicantMailingAddress}
                                    onChange={(event) => {
                                        setApplicantMailingAddress(event.target.value);
                                    }
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {/* Customer Permanent Address  */}
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <TextField
                                    name="customerPermanentAddress"
                                    required
                                    fullWidth
                                    disabled={true}
                                    id="customerPermanentAddress"
                                    label="Permanent Address"
                                    autoComplete={"permanent-address"}
                                    value={applicantPermanentAddress}
                                    onChange={(event) => {
                                        setApplicantPermanentAddress(event.target.value);
                                    }
                                    }
                                />
                            </FormControl>
                        </Grid>

                        <Divider variant={"middle"} flexItem />

                        {/* Next of kin declaration  */}
                        <Grid mt={3} item xs={12} textAlign={"center"} alignItems={"center"} >
                            <Chip label="I hereby nominate the following as my next of kin (attach copy)" color="primary" />
                        </Grid>

                        {/* Next of Kin Full Name  */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    name="nextOfKinFullName"
                                    required
                                    disabled={true}
                                    fullWidth
                                    id="nextOfKinFullName"
                                    label="Full Name"
                                    value={nextOfKinFullName}
                                    onChange={(event) => {
                                        setNextOfKinFullName(event.target.value);
                                    }
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {/* Next of Kin CNIC  */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    name="nextOfKinCnic"
                                    required
                                    disabled={true}
                                    fullWidth
                                    id="nextOfKinCnic"
                                    label="CNIC"
                                    value={nextOfKinCnic}
                                    onChange={(event) => {
                                        setNextOfKinCnic(event.target.value);
                                    }
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {/* Next of Kin Relationship  */}
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <TextField
                                    required={true}
                                    name="nextOfKinRelationship"
                                    id="nextOfKinRelationship"
                                    disabled={true}
                                    label="Relationship"
                                    value={nextOfKinRelationship}
                                    onChange={(event) => {
                                        setNextOfKinRelationship(event.target.value);
                                    }
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {/* Next of Kin Email  */}
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <TextField
                                    name="nextOfKinEmail"
                                    fullWidth
                                    id="nextOfKinEmail"
                                    disabled={true}
                                    label="Email"
                                    autoComplete={"email"}
                                    value={nextOfKinEmail}
                                    onChange={(event) => {
                                        setNextOfKinEmail(event.target.value);
                                    }
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {/* Next of Kin Cell Number  */}
                        <Grid item xs={12} sm={4}>
                            <FormControl fullWidth>
                                <TextField
                                    name="nextOfKinCellNumber"
                                    required
                                    disabled={true}
                                    fullWidth
                                    id="nextOfKinCellNumber"
                                    label="Cell Number"
                                    autoComplete={"tel"}
                                    value={nextOfKinContactNumber}
                                    onChange={(event) => {
                                        setNextOfKinContactNumber(event.target.value);
                                    }
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {/* Next of Kin Mailing Address  */}
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <TextField
                                    name="customerMailAddress"
                                    required
                                    fullWidth
                                    id="customerMailAddress"
                                    disabled={true}
                                    label="Mailing Address"
                                    autoComplete={"mailing-address"}
                                    value={nextOfKinMailingAddress}
                                    onChange={(event) => {
                                        setNextOfKinMailingAddress(event.target.value);
                                    }
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {/* Next of Kin Permanent Address  */}
                        <Grid item xs={12} sm={12}>
                            <FormControl fullWidth>
                                <TextField
                                    name="customerPermanentAddress"
                                    required
                                    fullWidth
                                    id="customerPermanentAddress"
                                    disabled={true}
                                    label="Permanent Address"
                                    autoComplete={"permanent-address"}
                                    value={nextOfKinPermanentAddress}
                                    onChange={(event) => {
                                        setNextOfKinPermanentAddress(event.target.value);
                                    }
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {/* Empty Space  */}
                        <Grid mt={3} item xs={12}>
                        </Grid>

                        {/* Category */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    name="bookingCategory"
                                    required
                                    fullWidth
                                    id="bookingCategory"
                                    disabled={true}
                                    label="Category"
                                    autoComplete={"category"}
                                    value={bookingCategory}
                                />
                            </FormControl>
                        </Grid>

                        {/* Size */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    name="bookingSize"
                                    required
                                    fullWidth
                                    id="bookingSize"
                                    disabled={true}
                                    label="Size"
                                    autoComplete={"category"}
                                    value={bookingSize}
                                />

                            </FormControl>
                        </Grid>


                        {/* Company Name  */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    name="realtorCompanyName"
                                    required
                                    disabled={true}
                                    fullWidth
                                    id="realtorCompanyName"
                                    label="Company Name"
                                    value={realtorCompanyName}
                                    onChange={(newValue) => {
                                        setRealtorCompanyName(newValue.target.value);
                                    }
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {/* Realtor Contact Number  */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    name="realtorContactNumber"
                                    required
                                    disabled={true}
                                    fullWidth
                                    id="realtorContactNumber"
                                    label="Contact Number"
                                    value={realtorContactNumber}
                                    onChange={(newValue) => {
                                        setRealtorContactNumber(newValue.target.value);
                                    }
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {/* Rep Name  */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    name="realtorRepName"
                                    required
                                    disabled={true}
                                    fullWidth
                                    id="realtorRepName"
                                    label="Rep Name"
                                    value={realtorRepresentativeName}
                                    onChange={(newValue) => {
                                        setRealtorRepresentativeName(newValue.target.value);
                                    }
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {/* Realtor CNIC  */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    name="realtorCnic"
                                    required
                                    fullWidth
                                    disabled={true}
                                    id="realtorCnic"
                                    label="CNIC"
                                    value={realtorRepresentativeCnic}
                                    onChange={(newValue) => {
                                        setRealtorRepresentativeCnic(newValue.target.value);
                                    }
                                    }
                                />
                            </FormControl>
                        </Grid>

                        {/* Empty Space  */}
                        <Grid mt={3} item xs={12}>
                        </Grid>


                        {/* Mode of Payment */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>

{/*                                <InputLabel id="bookingModeOfPaymentLabel">Mode of Payment</InputLabel>
                                <Select
                                    labelId="bookingModeOfPaymentLabel"
                                    id="bookingModeOfPayment"
                                    value={bookingModeOfPayment}
                                    label="Mode of Payment"
                                    onChange={(newValue) => {
                                        setBookingModeOfPayment(newValue.target.value);
                                    }}
                                >
                                    <MenuItem value={"Sales Partner Account"}>Sales Partner Account</MenuItem>
                                    <MenuItem value={"Deposit Slip"}>Deposit Slip</MenuItem>
                                    <MenuItem value={"Demand Draft"}>Demand Draft</MenuItem>
                                    <MenuItem value={"Purchase Order"}>Purchase Order</MenuItem>
                                </Select>*/}


                                <TextField
                                    name="bookingModeOfPayment"
                                    required
                                    fullWidth
                                    disabled={true}
                                    id="bookingModeOfPayment"
                                    label="Mode of Payment"
                                    value={bookingModeOfPayment}
                                    onChange={(newValue) => {
                                        // setRealtorRepresentativeCnic(newValue.target.value);
                                    }
                                    }
                                />

                            </FormControl>
                        </Grid>

                        {/* Booking Price  */}
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <TextField
                                    // disabled
                                    name="bookingPrice"
                                    value={bookingPrice}
                                    disabled={true}
                                    required
                                    fullWidth
                                    id="bookingPrice"
                                    label="Booking Price"
                                />
                            </FormControl>
                        </Grid>

                        {bookingModeOfPayment === "Sales Partner Account" ?
                            <Grid mt={3} item xs={12}>
                                <Typography align={"center"}>
                                    Note: This Booking is made through Sales Partner’s account. Customer must take receiving from
                                    Sales Partner who is booking the plot.
                                </Typography>
                            </Grid>
                            :
                            <>

                                {/* Bank Name  */}
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            disabled={true}
                                            name="bankName"
                                            required
                                            fullWidth
                                            id="bankName"
                                            label="Bank Name"
                                            value={bookingBankName}
                                            onChange={(newValue) => {
                                                setBookingBankName(newValue.target.value);
                                            }
                                            }
                                        />
                                    </FormControl>
                                </Grid>

                                {/* Branch Name  */}
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="branchName"
                                            required
                                            fullWidth
                                            disabled={true}
                                            id="branchName"
                                            label="Branch Name"
                                            value={bookingBankBranchName}
                                            onChange={(newValue) => {
                                                setBookingBankBranchName(newValue.target.value);}
                                            }
                                        />
                                    </FormControl>
                                </Grid>

                                {/* D.Slip, DD, PO No.  */}
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="bookingDsDdPoNo"
                                            required
                                            fullWidth
                                            value={bookingDsDdPoNo}
                                            disabled={true}
                                            id="bookingDsDdPoNo"
                                            label="D.Slip/DD/PO No."
                                            onChange={(newValue) => {
                                                setBookingDsDdPoNo(newValue.target.value);}
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </>
                        }

                        {/* Empty Space  */}
                        <Grid mt={3} item xs={12}>
                        </Grid>

                        {/* Booking Rep  */}
                        <Grid item xs={12} sm={6} textAlign={"center"}>
                            <FormControl variant="standard">
                                <Input
                                    id="bookingRealtor"
                                    aria-describedby="bookingRealtor-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                                <FormHelperText id="bookingRealtor-helper-text" style={{fontSize:"medium", textAlign:"center"}}>Booking Rep</FormHelperText>
                            </FormControl>
                        </Grid>

                        {/* Applicant Signature  */}
                        <Grid item xs={12} sm={6} textAlign={"center"}>
                            <FormControl variant="standard">
                                <Input
                                    id="applicantSignature"
                                    aria-describedby="applicantSignature-helper-text"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                                <FormHelperText id="applicantSignature-helper-text" style={{fontSize:"medium", textAlign:"center"}}>Applicant Signature</FormHelperText>
                            </FormControl>
                        </Grid>

                        {/* Empty Space  */}
                        <Grid mt={5} item xs={12}>
                        </Grid>

                        <Grid container spacing={2} mt={3}>

                            {/* Applicant Image */}
                            <Grid item xs={12} sm={4}>
                                <Grid container spacing={2} mt={3}>
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant={"h5"} >
                                            Applicant Image
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12} sm={6}>
                                        {(applicantImageUrl) && <><a href={applicantImageUrl} target={'_blank'}><img width={'150px'} src={applicantImageUrl} alt="uploaded_image" /></a></>}
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* Applicant CNIC Front Image */}
                            <Grid item xs={12} sm={4}>

                                <Grid container spacing={2} mt={3}>
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant={"h5"} >
                                            Applicant CNIC Front Image
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={4} sm={4}>
                                        {(applicantCnicFrontImageUrl) && <><a href={applicantCnicFrontImageUrl} target={'_blank'}><img width={'150px'} src={applicantCnicFrontImageUrl} alt="uploaded_image" /></a></>}

                                    </Grid>
                                </Grid>

                            </Grid>

                            {/* Applicant CNIC Back Image */}
                            <Grid item xs={12} sm={4}>

                                <Grid container spacing={2} mt={3}>
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant={"h5"} >
                                            Applicant CNIC Back Image
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={4} sm={4}>
                                        {(applicantCnicBackImageUrl) && <><a href={applicantCnicBackImageUrl} target={'_blank'}><img width={'150px'} src={applicantCnicBackImageUrl} alt="uploaded_image" /></a></>}


                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>


                        {/* NEXT OF KIN */}
                        <Grid container spacing={2} mt={3}>

                            {/* NEXT OF KIN CNIC Front Image */}
                            <Grid item xs={12} sm={4}>

                                <Grid container spacing={2} mt={3}>
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant={"h5"} >
                                            NEXT OF KIN CNIC Front Image
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={4} sm={4}>
                                        {(nextOfKinCnicFrontImageUrl) && <><a href={nextOfKinCnicFrontImageUrl} target={'_blank'}><img width={'150px'} src={nextOfKinCnicFrontImageUrl} alt="uploaded_image" /></a></>}
                                    </Grid>
                                </Grid>

                            </Grid>

                            {/* NEXT OF KIN CNIC Back Image */}
                            <Grid item xs={12} sm={4}>

                                <Grid container spacing={2} mt={3}>
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant={"h5"} >
                                            NEXT OF KIN CNIC Back Image
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={4} sm={4}>
                                        {(nextOfKinCnicBackImageUrl) && <><a href={nextOfKinCnicBackImageUrl} target={'_blank'}><img width={'150px'} src={nextOfKinCnicBackImageUrl} alt="uploaded_image" /></a></>}
                                    </Grid>
                                </Grid>

                            </Grid>


                            {/* Payment Identifier Image */}
                            <Grid item xs={12} sm={4}>

                                <Grid container spacing={2} mt={3}>
                                    <Grid item xs={12} sm={12}>
                                        <Typography variant={"h5"} >
                                            D.Slip/DD/PO No. Image
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={4} sm={4}>
                                        {(paymentIdentifierImageUrl) && <><a href={paymentIdentifierImageUrl} target={'_blank'}><img width={'150px'} src={paymentIdentifierImageUrl} alt="uploaded_image" /></a></>}
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>






                    </Grid>

                </Box>
            </Box>
            <Copyright sx={{ mt: 5 }} />
        </Container>
    </ThemeProvider>

    );




  return <Sidebar childComponent={bookingForm} index={0}  title={"New Booking"} />;
}