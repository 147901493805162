import React from 'react'

const SectionTitle4 = (props) => {
    return(
        <div className="row">
            <div className="wpo-section-title">
                {/*<span>{props.subTitle}</span>*/}
                <h3>{props.MainTitle}</h3>
            </div>
        </div>
    )
}

export default SectionTitle4;