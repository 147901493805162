import simg from '../images/service-single/1.jpg'
import simg2 from '../images/service-single/2.jpg'
import simg3 from '../images/service-single/3.jpg'
import simg4 from '../images/service-single/4.jpg'
import simg5 from '../images/service-single/5.jpg'
import simg6 from '../images/service-single/6.jpg'
import simg7 from '../images/service-single/7.jpg'
import simg8 from '../images/service-single/8.jpg'
import simg9 from '../images/service-single/9.jpg'
import simg10 from '../images/service-single/10.jpg'
import simg11 from '../images/service-single/11.jpg'
import simg12 from '../images/service-single/12.jpg'
import simg13 from '../images/service-single/13.jpg'
import sales from '../images/service/sales-main.jpg'
import marketing from '../images/service/marketing-main.jpg'

import sSingleimg1 from '../images/service-single/s1.jpg'
import sSingleimg2 from '../images/service-single/s2.jpg'


import ins1 from '../images/instragram/7.jpg'
import ins2 from '../images/instragram/8.jpg'
import ins3 from '../images/instragram/9.jpg'
import ins4 from '../images/instragram/10.jpg'
import ins5 from '../images/instragram/11.jpg'
import ins6 from '../images/instragram/12.jpg'


const Services = [
    {
        Id: '1',
        sImg:simg,
        sTitle: 'Perfect Planning',
        description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'fi flaticon-planning',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        sinst:ins1,
    },
    {
        Id: '2',
        sImg:simg2,
        sTitle: 'Professional Design',
        description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'fi flaticon-blueprint',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        sinst:ins2,
    },
    {
        Id: '3',
        sImg:simg3,
        sTitle: 'Best Interior',
        description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'fi flaticon-double-bed',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        sinst:ins3,
    },
    {
        Id: '4',
        sImg:simg4,
        sTitle: 'Furniture',
        description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'fi flaticon-armchair',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        sinst:ins4,
    },
    {
        Id: '5',
        sImg:simg5,
        sTitle: 'Complete Decoration',
        description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'fi flaticon-furniture',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        sinst:ins5,
    },
    {
        Id: '6',
        sImg:simg6,
        sTitle: 'Exterior Design',
        description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'fi flaticon-interior-design',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        sinst:ins6,
    },
    {
        Id: '7',
        sImg:simg7,
        sTitle: 'Interior Design',
        description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'fi flaticon-interior-design',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        sinst:ins6,
    },
    {
        Id: '8',
        sImg:simg8,
        sTitle: 'Room Decoration',
        description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        sinst:ins6,
    },
    {
        Id: '9',
        sImg:simg9,
        sTitle: 'Planning',
        description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        sinst:ins6,
    },
    {
        Id: '10',
        sImg:simg10,
        sTitle: 'Lighting',
        description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        sinst:ins6,
    },
    {
        Id: '11',
        sImg:simg11,
        sTitle: 'Interior Design',
        description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        sinst:ins6,
    },
    {
        Id: '12',
        sImg:simg12,
        sTitle: 'Room Decoration',
        description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        sinst:ins6,
    },
    {
        Id: '13',
        sImg:simg13,
        sTitle: 'Planning',
        description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        sinst:ins6,
    },
    {
        Id: '14',
        name: 'sales',
        sImg:sales,
        sTitle: 'Sales',
        description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'fi flaticon-interior-design',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        sinst:ins6,
    },
    {
        Id: '15',
        name: 'marketing',
        sImg:marketing,
        sTitle: 'Marketing',
        description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
        des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'fi flaticon-interior-design',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
        sinst:ins6,
    },
]
export default Services;