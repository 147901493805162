import React from 'react'
import {Link as NavLink} from 'react-router-dom'
import Logo from '../../images/footer_logo.png'
import Link from "@mui/material/Link";


const Footer = (props) => {

    const getCurrentYear = () => {
        var today = new Date();
        var year = today.getFullYear();
        return year;
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <footer className={`wpo-site-footer ${props.ftClass}`}>
            <div className="wpo-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    <NavLink onClick={ClickHandler} className="logo" to="/"><img width={'75%'}
                                                                                                 src={Logo}
                                                                                                 alt=""/></NavLink>
                                </div>
                                <p>In an ecosystem based on transparency, accessibility is a requisite. Feel free to
                                    contact us anytime, and find solace in your investment.</p>
                            </div>
                        </div>
                        <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Our Services</h3>
                                </div>
                                <ul>
                                    <li><NavLink onClick={ClickHandler} to="/services/sales">Sales</NavLink></li>
                                    <li><NavLink onClick={ClickHandler} to="/services/marketing">Marketing</NavLink>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget wpo-service-link-widget">
                                <div className="widget-title">
                                    <h3>Contact </h3>
                                </div>
                                <div className="contact-ft">
                                    <ul>
                                        <li><i className="fi flaticon-location"></i>Top Floor, Khalid Plaza, Jinnah
                                            Avenue, Blue Area, Islamabad
                                        </li>
                                        <li><i className="fi flaticon-telephone"></i>+92 311 1786 224</li>
                                        <li><i className="fi flaticon-email"></i>info@propertiesbag.com</li>
                                    </ul>
                                </div>
                            </div>

                            <div className={"widget about-widget"}>

                                <ul>
                                    <li>
                                        <Link target={"_blank"}
                                              href="https://www.facebook.com/PropertiesBagoffical?mibextid=ZbWKwL">
                                            <i className="ti-facebook"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link target={"_blank"}
                                              href="https://twitter.com/pbcomofficial?s=21&t=9Er2HO_5j8TTiECfTUSBew">
                                            <i className="ti-twitter"></i>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link target={"_blank"}
                                              href="https://www.instagram.com/propertiesbagofficial?igsh=a2F5a3hqenpuYjVw">
                                            <i className="ti-instagram"></i>
                                        </Link>

                                    </li>
                                    <li>
                                        <Link target={"_blank"} href="https://www.linkedin.com/company/propertiesbag-com/">
                                            <i className="ti-linkedin"></i>
                                        </Link>
                                    </li>
                                </ul>

                            </div>


                        </div>

                        <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="widget instagram">
                                <div className="widget-title">
                                    <h3>Our Location</h3>
                                </div>
                                <section className="wpo-footer-map-section">
                                    <div className="wpo-contact-map">
                                        <iframe
                                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13273.355071931404!2d73.0776637173828!3d33.72605509999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfc07ff5d1b54f%3A0xb5d9af2cee162005!2sKhalid%20Plaza!5e0!3m2!1sen!2s!4v1708371303997!5m2!1sen!2s"
                                            loading="lazy"
                                            referrerPolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                </section>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="wpo-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <ul>
                            <li>&copy; {getCurrentYear()} PropertiesBag.com. All Rights Reserved.</li>
                                {/*<li><Link onClick={ClickHandler} to="/">Terms of use |</Link> <Link onClick={ClickHandler} to="/">Privacy Environmental Policy</Link></li>*/}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;