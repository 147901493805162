import * as React from "react";
import {createTheme} from "@mui/material/styles";
import Api from "../../helpers/api";
import Sidebar from "../common/Sidebar";
import BookingDataTable from "../datatables/BookingDataTable";
import BookingDataTable1 from "../datatables/BookingDataTable1";
import {useCallback} from "react";
import {set} from "react-hook-form";
import jwt_decode from "jwt-decode";
import {Outlet} from "react-router-dom";

const theme = createTheme();


export default function Bookings() {
    const [bookings, setBookings] = React.useState([]);

    const jwtDecoded:any = localStorage.getItem("accessToken") !== null ? jwt_decode(localStorage.getItem(`accessToken`) as string) : null;


    const api = new Api();

    React.useEffect(() => {

        if(jwtDecoded.roles.includes("ADMIN") || jwtDecoded.roles.includes("CEO") || jwtDecoded.roles.includes("ACCOUNT_MANAGER")) {
            api.getAllBookings().then((response) => {
                console.log(response);
                setBookings(response.data.data);
            })
        }
        else {
            api.getAllBookingsOfUser(jwtDecoded.uid).then((response) => {
                console.log(response);
                setBookings(response.data.data);
            })
        }

    }, []);

    // Update individual booking in bookings
    const updateBookingData = (updatedBooking: any) => {
        let newData:any = { ...bookings };

        newData.map((item:any) => {
            if (item.id === updatedBooking.id) {
                return updatedBooking;
            }
            // return item;
        })

        setBookings(newData);
    };

    return (
        <>
        <Sidebar childComponent={<BookingDataTable jwtDecoded={jwtDecoded} updateBookingData={updateBookingData} bookings={bookings} />} index={1} title={"All Bookings"} />

    <Outlet />
        </>
        );

}