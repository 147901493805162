import React, {useState} from 'react';
import SimpleReactValidator from "simple-react-validator";
import {useNavigate} from "react-router-dom";

import './style.scss';
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {Alert, Collapse} from "@mui/material";
import Button from "@mui/material/Button";
import {Controller, useForm} from "react-hook-form";
import Box from "@mui/material/Box";
import Api from "../../helpers/api";
import {isExpired} from "react-jwt";
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {styled} from "@mui/material/styles";

const LoginPage = (props) => {
    const [value, setValue] = useState({
        email: 'user@gmail.com',
        password: '123456',
        remember: false,
    });

    const changeHandler = (e) => {
        setValue({...value, [e.target.name]: e.target.value});
        validator.showMessages();
    };

    const rememberHandler = () => {
        setValue({...value, remember: !value.remember});
    };

    const [validator] = React.useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));

    const { handleSubmit, control, reset, formState: { errors } } = useForm();

    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#0960e6");
    // let [color, setColor] = useState("blue");

    const navigate = useNavigate()

    const api = new Api();


    const [openError, setOpenError] = React.useState(false);
    const [loginError, setLoginError] = React.useState('');

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    // Use effect
    React.useEffect(() => {
        const accessToken = localStorage.getItem('accessToken');
        const isTokenExpired = accessToken !== null ? isExpired(accessToken) : null;

        if(accessToken !== null && !isTokenExpired) {
            // navigate('/dashboard');
            window.location.replace('/dashboard');
        }

    }, []);


    const onSubmit1 = (data) => {

        let booking = {
            username: data.email,
            password: data.password
        }

        api.loginUser(booking).then((response) => {
            // console.log(response);
            // setLoading(true)

            if(response.data.status === 200) {

                const accessToken = response.data.tokenDetails.accessToken.token;
                const refreshToken = response.data.tokenDetails.refreshToken.token;

                localStorage.setItem("accessToken", accessToken);
                localStorage.setItem("refreshToken", refreshToken);

                //set token to axios common header
                // setAuthToken(token);

                // setLoading(false)

                // Navigate to dashboard
                // navigate('/dashboard');
                window.location.replace('/dashboard');
            }
            else {
                setLoginError(response?.data?.message);
                setOpenError(true);
                setLoading(false)
            }

        }).catch((error) => {
            console.log(error);
            setLoading(false)
        });

    };


    const CustomButton = styled(Button)({
        backgroundColor: '#0077b6',
        color: '#fff',
        '&:hover': {
            backgroundColor: '#0077b6',
            color: '#fff',
        },
        '&:active': {
        },
        '&:focus': {
        }
    });



    return (
<>
    <Grid className="loginWrapper">
            <Grid className="loginForm">

                <CustomButton variant="contained" startIcon={<ArrowBackIcon />} href="/home">
                    Back
                </CustomButton>
                <h2>Sign In</h2>
                <p>Login rights reserved for authorized personnel only.</p>
                <Box component={"form"} noValidate onSubmit={handleSubmit(onSubmit1)} >
                    <Grid container spacing={3}>


                        <Grid item xs={12} sm={12}>
                            <Collapse in={openError}>
                                <Alert variant="filled" severity="error"
                                       action={
                                           <IconButton
                                               aria-label="close"
                                               color="inherit"
                                               size="small"
                                               onClick={() => {
                                                   setOpenError(false);
                                               }}
                                           >
                                               <Close fontSize="inherit" />
                                           </IconButton>
                                       }

                                >
                                    {loginError}
                                </Alert>
                            </Collapse>
                        </Grid>


                        <Grid item xs={12}>
                            <Controller
                                render={({ field: { name, value, onChange } }) => (
                                    <TextField
                                        name={name}
                                        id="email"
                                        required
                                        fullWidth
                                        label="Email"
                                        type={"email"}
                                        autoComplete={"email"}
                                        error={Boolean(errors["email"])}
                                        helperText={errors["email"] ? String(errors["email"].message) : ""}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )}
                                control={control}
                                name={'email'}
                                rules={{ required: { value: true, message: 'Email is required' } }}
                            />

                        </Grid>
                        <Grid item xs={12}>
                            <Controller
                                render={({ field: { name, value, onChange } }) => (
                                    <TextField
                                        name={name}
                                        type={"password"}
                                        required
                                        fullWidth
                                        id="password"
                                        label="Password"
                                        autoComplete={"password"}
                                        error={Boolean(errors["password"])}
                                        value={value}
                                        onChange={onChange}
                                        helperText={errors["password"] ? String(errors["password"].message) : ""}
                                    />
                                )}
                                control={control}
                                name={'password'}
                                rules={{ required: { value: true, message: 'Password is required' } }}
                            />


                        </Grid>
                        <Grid item xs={12}>
                            <Grid className="formFooter">
                                <CustomButton variant={'contained'} fullWidth type="submit">Login</CustomButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
                <div className="shape-img">
                    <i className="fi flaticon-honeycomb"></i>
                </div>
            </Grid>
        </Grid>
</>
    )
};

export default LoginPage;