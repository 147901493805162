export class User {
    id: string | undefined;
    firstName: string | undefined;
    lastName: string | undefined;

    constructor(user: any) {
        this.id = user.id;
        this.firstName = user.firstName;
        this.lastName = user.lastName;
    }

}