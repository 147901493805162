import * as React from 'react';
import {ChangeEvent, useState} from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {createTheme} from '@mui/material/styles';
import Api from "../../../helpers/api";
import jwt_decode from "jwt-decode";
import {ClipLoader} from "react-spinners";
import {useForm} from "react-hook-form";

const theme = createTheme();

const bucketName = process.env.REACT_APP_BUCKET_NAME;
export default function BookingFormStep2(props:any) {

    const booking = props.booking;
    const handleNext = props.handleNext;

    const api = new Api();

    const jwtDecoded:any = localStorage.getItem("accessToken") !== null ? jwt_decode(localStorage.getItem(`accessToken`) as string) : null;

    // Use effect
    React.useEffect(() => {
        if(jwtDecoded) {

        }
    }, []);


    const [applicantImageUrl, setApplicantImageUrl] = React.useState('');
    const [applicantCnicFrontImageUrl, setApplicantCnicFrontImageUrl] = React.useState('');
    const [applicantCnicBackImageUrl, setApplicantCnicBackImageUrl] = React.useState('');
    const [nextOfKinImageUrl, setNextOfKinImageUrl] = React.useState('');
    const [nextOfKinCnicFrontImageUrl, setNextOfKinCnicFrontImageUrl] = React.useState('');
    const [nextOfKinCnicBackImageUrl, setNextOfKinCnicBackImageUrl] = React.useState('');
    const [paymentIdentifierImageUrl, setPaymentIdentifierImageUrl] = React.useState('');

    let [loading, setLoading] = useState(false);
    let [color, setColor] = useState("#0960e6");

    const handleApplicantImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return
        uploadImage(event.target.files[0], 'APPLICANT_IMAGE');
    }

    const handleApplicantCnicFrontImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return
        uploadImage(event.target.files[0], "APPLICANT_CNIC_FRONT_IMAGE");
    }

    const handleApplicantCnicBackImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return
        uploadImage(event.target.files[0], 'APPLICANT_CNIC_BACK_IMAGE');
    }

    const handleNextOfKinImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return
        uploadImage(event.target.files[0], 'NEXT_OF_KIN_IMAGE');
    }

    const handleNextOfKinCnicFrontImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return
        uploadImage(event.target.files[0], 'NEXT_OF_KIN_CNIC_FRONT_IMAGE');
    }

    const handleNextOfKinCnicBackImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return
        uploadImage(event.target.files[0], 'NEXT_OF_KIN_CNIC_BACK_IMAGE');
    }

    const handlePaymentIdentifierImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) return
        uploadImage(event.target.files[0], 'PAYMENT_IDENTIFIER_IMAGE');
    }

    const handleImageUrl = (fileName: string, documentType: string) => {

        const imageUrl = `https://${bucketName}.s3.amazonaws.com/${fileName}`;

        if(documentType === 'APPLICANT_IMAGE') {
            setApplicantImageUrl(imageUrl);
        }
        else if(documentType === 'APPLICANT_CNIC_FRONT_IMAGE') {
            setApplicantCnicFrontImageUrl(imageUrl);
        }
        else if(documentType === 'APPLICANT_CNIC_BACK_IMAGE') {
            setApplicantCnicBackImageUrl(imageUrl);
        }
        else if(documentType === 'NEXT_OF_KIN_IMAGE') {
            setNextOfKinImageUrl(imageUrl);
        }
        else if(documentType === 'NEXT_OF_KIN_CNIC_FRONT_IMAGE') {
            setNextOfKinCnicFrontImageUrl(imageUrl);
        }
        else if(documentType === 'NEXT_OF_KIN_CNIC_BACK_IMAGE') {
            setNextOfKinCnicBackImageUrl(imageUrl);
        }
        else if(documentType === 'PAYMENT_IDENTIFIER_IMAGE') {
            setPaymentIdentifierImageUrl(imageUrl);
        }

    }

    const uploadImage = (file: any, documentType: any) => {

        setLoading(true);

        const params = {
            file: file,
            fileName: booking.formNumber + '-' + documentType + '.' + file.name?.split('.').pop(),
            documentType: documentType
        }

        api.uploadImage(params).then((response) => {
                console.log(response);
                handleImageUrl(params.fileName, params.documentType);
                setLoading(false);
                api.updateBookingDocument(booking.id, {documentType: params.documentType, key: params.fileName}).then((response) => {
                    console.log(response);
                }).catch((error) => {
                    console.log(error);
                });
            }
        ).catch((error) => {
                console.log(error);
                setLoading(false);
            }
        );

        setLoading(false);

    }

    const { handleSubmit, control, reset, formState: { errors } } = useForm();


    const submitForm = (e: any) => {

        // alert(e);


        api.updateBookingStatus(booking.id, {bookingStatus: 'PENDING'}).then((response) => {
            console.log(response);
            handleNext(response.data.data);

        }).catch((error) => {
            console.log(error);
        }
        );

        e.preventDefault();


    }

    const handleSubmitForm = (data: any) => {
        api.updateBookingStatus(booking.id, {bookingStatus: 'PENDING'}).then((response) => {
            console.log(response);
            handleNext(response.data.data);

        }).catch((error) => {
                console.log(error);
            }
        );
    }



    const bookingForm = (

            <Box component="form" onSubmit={submitForm}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    // alignItems: 'center',
                }}
            >

                <Grid container textAlign={"center"} alignItems={"center"} spacing={2}>

                    <Grid item xs={12} sm={12}>
                        <Typography variant={"h4"} >
                            Application Form - {booking?.formNumber}
                        </Typography>
                    </Grid>

                </Grid>

                {/* APPLICANT */}

                <Grid container spacing={2} mt={3}>

                    {/* Applicant Image */}
                    <Grid item xs={12} sm={4}>
                        <Grid container spacing={2} mt={3}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant={"h5"} >
                                    Applicant Image
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                {(applicantImageUrl) && <img width={'150px'} src={applicantImageUrl} alt="uploaded_image" />}
                                <Button variant="contained" component="label">
                                    <input required accept="image/*" type="file" onChange={handleApplicantImageChange} />
                                    <ClipLoader
                                        color={"fff"}
                                        loading={loading}
                                        size={20}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Applicant CNIC Front Image */}
                    <Grid item xs={12} sm={4}>

                        <Grid container spacing={2} mt={3}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant={"h5"} >
                                    Applicant CNIC Front Image
                                </Typography>
                            </Grid>

                            <Grid item xs={4} sm={4}>
                                {(applicantCnicFrontImageUrl) && <img width={'150px'} src={applicantCnicFrontImageUrl} alt="uploaded_image" />}
                                <Button variant="contained" component="label">
                                    <input required accept="image/*" type="file" onChange={handleApplicantCnicFrontImageChange} />
                                    <ClipLoader
                                        color={"fff"}
                                        loading={loading}
                                        size={20}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </Button>

                            </Grid>
                        </Grid>

                    </Grid>

                    {/* Applicant CNIC Back Image */}
                    <Grid item xs={12} sm={4}>

                        <Grid container spacing={2} mt={3}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant={"h5"} >
                                    Applicant CNIC Back Image
                                </Typography>
                            </Grid>

                            <Grid item xs={4} sm={4}>
                                {(applicantCnicBackImageUrl) && <img width={'150px'} src={applicantCnicBackImageUrl} alt="uploaded_image" />}
                                <Button variant="contained" component="label">
                                    <input required accept="image/*" type="file" onChange={handleApplicantCnicBackImageChange} />
                                    <ClipLoader
                                        color={"fff"}
                                        loading={loading}
                                        size={20}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </Button>

                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>


                {/* NEXT OF KIN */}
                <Grid container spacing={2} mt={3}>

                    {/* NEXT OF KIN CNIC Front Image */}
                    <Grid item xs={12} sm={4}>

                        <Grid container spacing={2} mt={3}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant={"h5"} >
                                    NEXT OF KIN CNIC Front Image
                                </Typography>
                            </Grid>

                            <Grid item xs={4} sm={4}>
                                {(nextOfKinCnicFrontImageUrl) && <img width={'150px'} src={nextOfKinCnicFrontImageUrl} alt="uploaded_image" />}
                                <Button variant="contained" component="label">
                                    <input required accept="image/*" type="file" onChange={handleNextOfKinCnicFrontImageChange} />
                                    <ClipLoader
                                        color={"fff"}
                                        loading={loading}
                                        size={20}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </Button>

                            </Grid>
                        </Grid>

                    </Grid>

                    {/* NEXT OF KIN CNIC Back Image */}
                    <Grid item xs={12} sm={4}>

                        <Grid container spacing={2} mt={3}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant={"h5"} >
                                    NEXT OF KIN CNIC Back Image
                                </Typography>
                            </Grid>

                            <Grid item xs={4} sm={4}>
                                {(nextOfKinCnicBackImageUrl) && <img width={'150px'} src={nextOfKinCnicBackImageUrl} alt="uploaded_image" />}
                                <Button variant="contained" component="label">
                                    <input required accept="image/*" type="file" onChange={handleNextOfKinCnicBackImageChange} />
                                    <ClipLoader
                                        color={"fff"}
                                        loading={loading}
                                        size={20}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </Button>

                            </Grid>
                        </Grid>

                    </Grid>


                    {/* Payment Identifier Image */}
                    <Grid item xs={12} sm={4}>

                        <Grid container spacing={2} mt={3}>
                            <Grid item xs={12} sm={12}>
                                <Typography variant={"h5"} >
                                    D.Slip/DD/PO No. Image
                                </Typography>
                            </Grid>

                            <Grid item xs={4} sm={4}>
                                {(paymentIdentifierImageUrl) && <img width={'150px'} src={paymentIdentifierImageUrl} alt="uploaded_image" />}
                                <Button variant="contained" component="label">
                                    <input required accept="image/*" type="file" onChange={handlePaymentIdentifierImageChange} />
                                    <ClipLoader
                                        color={"fff"}
                                        loading={loading}
                                        size={20}
                                        aria-label="Loading Spinner"
                                        data-testid="loader"
                                    />
                                </Button>

                            </Grid>
                        </Grid>

                    </Grid>

                </Grid>



                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    // onClick={submitForm}
                >
                    SUBMIT BOOKING
                </Button>




            </Box>

    );


  return bookingForm;
}