import React from 'react'
import {Link} from 'react-router-dom'
import abimg from '../../images/about2.jpg'
import abimg2 from '../../images/about3.jpg'


const About2 = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (
        <div className="wpo-about-area-s2 section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="wpo-about-img">
                            <div className="wpo-about-img-left">
                                <img src={abimg} alt="" width={350} height={460} />
                                    {/*<div className="wpo-about-img-text">
                                        <h2>25+</h2>
                                        <p>Years of Experience</p>
                                    </div>*/}
                            </div>
                            <div className="wpo-about-img-right">
                                <img src={abimg2} alt="" width={350} height={460} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 colsm-12">
                        <div className="wpo-about-text">
                            <div className="wpo-about-title">
                                <span>About Us</span>
                                <h2>We offer you a sense of security and guarantee, to facilitate your investment decisions.</h2>
                            </div>
                            <p>Propertiesbag.com aims at revolutionizing the real estate market through automation and digitization. In this revolution, we have the following role to execute:</p>
                            <ul className="ab-list">
                                <li>We bridge the gap between the project developers and you.</li>
                                <li>We provide you accountability and transparency of your financial transactions.</li>
                                <li>We provide you with veritable statistics, and authentic progress and plans of the project.</li>
                                <li>We provide you security regarding the legitimacy of the projects.</li>
                                <li>We provide widespread merit-based recognition to projects. </li>
                            </ul>
                            <div className="btns">
                                <Link to="/about" onClick={ClickHandler} className="theme-btn">Discover More</Link>
                                {/*<ul>
                                    <li className="video-holder">
                                        <VideoModal />
                                    </li>
                                    <li className="video-text">
                                        Watch Our Video
                                    </li>
                                </ul>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About2;