import React from 'react';
import SectionTitle4 from "../SectionTitle4";
import BarcodeVerifier from "../bookings/BarcodeVerifier";

const Verification = () => {

    return (
        <div className="wpo-project-area-s2 section-padding">
            <div className="container">
                <SectionTitle4
                    MainTitle={'To verify your booking, click to scan the bar code on the provided booking receipt.'}/>
                <BarcodeVerifier />
            </div>
        </div>  
    );
}

export default Verification;

