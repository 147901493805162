import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";

//history
//pages
import {ProtectedRoute} from "./ProtectedRoute";
import authHelper from "./helpers/authHelper";
import BookingForm from "./components/bookings/new/BookingForm";
import Bookings from "./components/bookings/Bookings";
import NewUser from "./components/users/NewUser";
import Users from "./components/users/Users";
import Unauthorized from "./components/common/Unauthorized";
import Dashboard from "./components/common/Dashboard";
import EditBooking from "./components/bookings/EditBooking";
import {PrintBooking} from "./components/bookings/PrintBooking";
import {ViewPrintBooking} from "./components/bookings/ViewPrintBooking";
import BarcodeVerifier from "./components/bookings/BarcodeVerifier";
import EditUser from "./components/users/EditUser";
import {PrintBookingReceipt} from "./components/bookings/PrintBookingReceipt";
import HomePage2 from "./main-component/HomePage2";
import ContactPage from "./main-component/ContactPage";
import ProjectPage from "./main-component/ProjectPage";
import BookingFormStep2 from "./components/bookings/new/BookingFormStep2";
import ServicePageS2 from "./main-component/ServicePageS2";
import ServiceSalesSinglePage from "./main-component/ServiceSalesSinglePage";
import ServiceMarketingSinglePage from "./main-component/ServiceMarketingSinglePage";
import About2Page from "./main-component/About2Page";
import LoginPage from "./main-component/LoginPage";
import VerificationPage from "./main-component/VerificationPage";
import Html5QrcodePlugin from "./components/Html5QrcodePlugin";
import BarcodeScanner from "./components/bookings/BarcodeScanner";

export function Routes1() {

    const jwtDecoded = localStorage.getItem("accessToken") !== null ? authHelper(localStorage.getItem("accessToken")) : null;
    const roles = ["ADMIN", "BOOKING_OFFICER", "SALES_MANAGER", "ACCOUNT_MANAGER", "CEO"];
    const boRoles = ["BOOKING_OFFICER"];

    return (
        <Routes>
            <Route
                path="/"
                element={<Navigate to="/home" replace />}
            />
            {/*<Route path="home" element={<Home />} />*/}

            <Route path='home' element={<HomePage2 />} />
            <Route path='services' element={<ServicePageS2 />} />
            <Route path='services/sales' element={<ServiceSalesSinglePage />} />
            <Route path='services/marketing' element={<ServiceMarketingSinglePage />} />
            <Route path='projects' element={<ProjectPage />} />
            {/*<Route path='projects/:id' element={<ProjectSinglePage />} />*/}
            {/*<Route path='blog' element={<BlogPageLeft />} />*/}
            <Route path='contact' element={<ContactPage />} />
            <Route path='about' element={<About2Page />} />

            <Route path="login" element={<LoginPage />} />
            <Route path="verification" element={<VerificationPage />} />
            {/*<Route path="verification1" element={<BarcodeScanner />} />*/}
            <Route path="unauthorized" element={<Unauthorized />} />
            <Route path="dashboard"
                   element={
                       <ProtectedRoute
                           redirectPath={!!jwtDecoded && !jwtDecoded.roles.some(role => roles.indexOf(role) >= 0) ? '/unauthorized' : '/login'}
                           isAllowed={!!jwtDecoded && jwtDecoded.roles.some(role => roles.indexOf(role) >= 0)}
                       >
                           <Dashboard />
                       </ProtectedRoute>
                   }
            />

            <Route
                path="bookings"
                element={
                    <ProtectedRoute
                        redirectPath={!!jwtDecoded ? '/unauthorized' : '/login'}
                        isAllowed={!!jwtDecoded && jwtDecoded.roles.length > 0}
                    >
                        <Bookings />
                    </ProtectedRoute>
                }
            >
            </Route>

            <Route
                path="bookings/:bookingId"
                element={
                    <ProtectedRoute
                        redirectPath={!!jwtDecoded ? '/unauthorized' : '/login'}
                        isAllowed={!!jwtDecoded && jwtDecoded.roles.length > 0}
                    >
                        <EditBooking />
                    </ProtectedRoute>
                }
            >
            </Route>

            <Route
                path="bookings/:bookingId/view"
                element={
                    <ProtectedRoute
                        redirectPath={!!jwtDecoded ? '/unauthorized' : '/login'}
                        isAllowed={!!jwtDecoded && jwtDecoded.roles.length > 0}
                    >
                        <ViewPrintBooking />
                    </ProtectedRoute>
                }
            >
            </Route>

            <Route
                path="bookings/:bookingId/step2"
                element={
                    <ProtectedRoute
                        redirectPath={!!jwtDecoded ? '/unauthorized' : '/login'}
                        isAllowed={!!jwtDecoded && jwtDecoded.roles.length > 0}
                    >
                        <BookingFormStep2 />
                    </ProtectedRoute>
                }
            >
            </Route>

            <Route
                path="bookings/:bookingId/print"
                element={
                    <ProtectedRoute
                        redirectPath={!!jwtDecoded ? '/unauthorized' : '/login'}
                        isAllowed={!!jwtDecoded && jwtDecoded.roles.length > 0}
                    >
                        <PrintBooking />
                    </ProtectedRoute>
                }
            >
            </Route>


            <Route
                path="bookings/:bookingId/print/receipt"
                element={
                    <ProtectedRoute
                        redirectPath={!!jwtDecoded ? '/unauthorized' : '/login'}
                        isAllowed={!!jwtDecoded && jwtDecoded.roles.length > 0}
                    >
                        <PrintBookingReceipt />
                    </ProtectedRoute>
                }
            >
            </Route>

            <Route
                path="bookings/form"
                element={
                    <ProtectedRoute
                        redirectPath={!!jwtDecoded && (!jwtDecoded.roles.includes('ADMIN') && !jwtDecoded.roles.includes('BOOKING_OFFICER')) ? '/unauthorized' : '/login'}
                        isAllowed={!!jwtDecoded && (jwtDecoded.roles.includes('ADMIN') || jwtDecoded.roles.includes('BOOKING_OFFICER'))}
                    >
                        <BookingForm />
                    </ProtectedRoute>
                }
            />

            <Route
                path="users"
                element={
                    <ProtectedRoute
                        redirectPath={!!jwtDecoded && !jwtDecoded.roles.includes('ADMIN') ? '/unauthorized' : '/login'}
                        isAllowed={!!jwtDecoded && jwtDecoded.roles.includes('ADMIN')}
                    >
                        <Users />
                    </ProtectedRoute>
                }
            />

            <Route
                path="users/:userId"
                element={
                    <ProtectedRoute
                        redirectPath={!!jwtDecoded && !jwtDecoded.roles.includes('ADMIN') ? '/unauthorized' : '/login'}
                        isAllowed={!!jwtDecoded && jwtDecoded.roles.includes('ADMIN')}
                    >
                        <EditUser />
                    </ProtectedRoute>
                }
            >
            </Route>


            <Route
                path="users/new"
                element={
                    <ProtectedRoute
                        redirectPath={!!jwtDecoded && !jwtDecoded.roles.includes('ADMIN') ? '/unauthorized' : '/login'}
                        isAllowed={!!jwtDecoded && jwtDecoded.roles.includes('ADMIN')}
                    >
                        <NewUser />
                    </ProtectedRoute>
                }
            />



        </Routes>



    );
}

export default Routes1