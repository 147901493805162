import * as React from 'react';
import Routes1 from "./routes";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from 'react-toastify';

export default function App() {

    return (
        <div className="App" id={'scrool'}>
            <Routes1 />
            <ToastContainer />
        </div>
    );
}