import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import {
    Avatar,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent
} from "@mui/material";
import Api from "../../helpers/api";
import {useNavigate, useParams} from "react-router-dom";
import Sidebar from "../common/Sidebar";
import Copyright from "../common/Copyright";
import {VerifiedUser} from "@mui/icons-material";
import {Controller, useForm} from "react-hook-form";
import ReactInputMask from "react-input-mask";
import jwt_decode from "jwt-decode";

const theme = createTheme();

export default function EditUser() {
    const navigate = useNavigate();
    const {userId} = useParams();
    const jwtDecoded:any = localStorage.getItem("accessToken") !== null ? jwt_decode(localStorage.getItem(`accessToken`) as string) : null;


    const [managers, setManagers] = React.useState([]);
    const [manager, setManager] = React.useState('');

    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [roles, setRoles] = React.useState([]);
    const [role, setRole] = React.useState('');
    const [cnic, setCnic] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [address, setAddress] = React.useState('');
    const [company, setCompany] = React.useState('');

    const [designations, setDesignations] = React.useState([]);
    const [designation, setDesignation] = React.useState('');

    const api = new Api();

    const { handleSubmit, control, reset, setValue, formState: { errors } } = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            cnic: '',
            phone: '',
            address: '',
            company: '',
            role: '',
            manager: '',
            designation: ''
        }
    });


    React.useEffect(() => {
        if(jwtDecoded) {
            api.getAllManagers().then((response) => {
                    setManagers(response.data.data);
                }
            );

            api.getAllManagerDesignations().then((response) => {
                    setDesignations(response.data.data);
                }
            );

            api.getAllUserRoles().then((response) => {
                    setRoles(response.data.data);
                }
            );

            api.getUserById(userId).then((response) => {
                // reset({...defaultValues});
                setValue('firstName', response.data.data.firstName);
                setValue('lastName', response.data.data.lastName);
                setValue('email', response.data.data.username);
                // setValue('password', response.data.data.password);
                setValue('cnic', response.data.data.cnic.number);
                setValue('phone', response.data.data.phoneNumbers[0]);
                setValue('company', response.data.data.companyName);
                setValue('role', response.data.data.roles[0]);
                setRole(response.data.data.roles[0]);
                setValue('designation', response.data.data?.designation);
                setValue('manager', response.data.data?.manager);
                setManager(response.data.data?.manager?.id);
                setValue('address', response.data.data.addressLocation?.address?.addressOne);

            }).catch((error) => {
                    console.log(error);
                }
            );
        }
    }, []);

    const handleRoleChange = (event: SelectChangeEvent) => {
        setRole(event.target.value);

        if(event.target.value !== 'BOOKING_OFFICER') {
            setManager('');
        }
    }

    const cellNumberMask = [/[3]/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];


    const handleSubmitForm = (data: any) => {
        // event.preventDefault();
        // const data = new FormData(event.currentTarget);

        let newUpdatedUser = {
            firstName: data.firstName,
            lastName: data.lastName,
            username: data.email,
            password: data.password,
            roles: [data.role],
            cnic: {
                number: data.cnic
            },
            phoneNumbers: [data.phone],
            addressLocation: {
                address: {
                    addressOne: data.address
                }
            },
            companyName: data.company,
            designation: data.designation,
            // manager: {}
            manager: {
                id: ''
            }
        }

        console.log("Manager", manager);

/*        if(data.manager !== null) {
            newUpdatedUser = {
                ...newUpdatedUser,
                manager: data.manager
            }
        }*/

        if(manager !== null) {
            newUpdatedUser = {
                ...newUpdatedUser,
                manager: {
                    id: manager
                }
            }
        }




        api.updateUser(userId, newUpdatedUser).then((response) => {
            console.log(response);

            if(response.status === 200) {
                // navigate('/users', {replace: true});
                window.location.reload();
            }

        }).catch((error) => {
            console.log(error);
        });

    };



    const newUserForm = (

        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="sm">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <VerifiedUser />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Update User
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit(handleSubmitForm)} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>

                            {/* First Name */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <TextField
                                            name={name}
                                            required
                                            fullWidth
                                            id={name}
                                            label="First Name"
                                            placeholder={value}
                                            error={Boolean(errors[name])}
                                            value={value}
                                            // defaultValue={firstName}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            onChange={(event) => {
                                                onChange(event);
                                                setFirstName(event.target.value);
                                            }}
                                        />
                                    )}
                                    control={control}
                                    name={'firstName'}
                                    rules={{ required: { value: true, message: 'First name is required' } }}
                                />

                            </Grid>

                            {/* Last Name */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <TextField
                                            name={name}
                                            required
                                            fullWidth
                                            id={name}
                                            label="Last Name"
                                            error={Boolean(errors[name])}
                                            value={value}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            onChange={(event) => {
                                                onChange(event);
                                                setLastName(event.target.value);
                                            }}
                                        />
                                    )}
                                    control={control}
                                    name={'lastName'}
                                    rules={{ required: { value: true, message: 'Last name is required' } }}
                                />
                            </Grid>

                            {/* Email */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <TextField
                                            name={name}
                                            required
                                            type={'email'}
                                            fullWidth
                                            id={name}
                                            label="Email Address"
                                            error={Boolean(errors[name])}
                                            value={value}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            onChange={(event) => {
                                                onChange(event);
                                                setEmail(event.target.value);
                                            }}
                                        />
                                    )}
                                    control={control}
                                    name={'email'}
                                    rules={{ required: { value: true, message: 'Email is required' } }}
                                />
                            </Grid>

                            {/* Password */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <TextField
                                            name={name}
                                            required
                                            type="text"
                                            fullWidth
                                            id={name}
                                            label="Password"
                                            error={Boolean(errors[name])}
                                            value={value}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            onChange={(event) => {
                                                onChange(event);
                                                setPassword(event.target.value);
                                            }}
                                        />
                                    )}
                                    control={control}
                                    name={'password'}
                                    rules={{ required: { value: false, message: 'Password is required' } }}
                                />
                            </Grid>

                            {/* CNIC Number */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <ReactInputMask
                                            mask={"99999-9999999-9"}
                                            value={value}
                                            disabled={false}
                                            onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => {
                                                onChange(event);
                                                setCnic(event.target.value);
                                            }}
                                        >
                                            <TextField
                                                name={name}
                                                required
                                                fullWidth
                                                id={name}
                                                label="CNIC"
                                                error={Boolean(errors[name])}
                                                value={value}
                                                helperText={errors[name] ? String(errors[name]?.message) : ""}
                                                placeholder={"12345-1234567-1"}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </ReactInputMask>
                                    )}
                                    control={control}
                                    name={'cnic'}
                                    rules={{ required: { value: true, message: 'CNIC is required' } }}
                                />
                            </Grid>


                            {/* Phone Number */}
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <ReactInputMask
                                            mask={cellNumberMask}
                                            value={value}
                                            disabled={false}
                                            onChange={(event: { target: { value: React.SetStateAction<string>; }; }) => {
                                                onChange(event);
                                                setPhone(event.target.value);
                                            }}
                                        >
                                            <TextField
                                                name={name}
                                                required
                                                fullWidth
                                                id={name}
                                                label="Cell Number"
                                                error={Boolean(errors[name])}
                                                value={value}
                                                helperText={errors[name] ? String(errors[name]?.message) : ""}
                                                placeholder={"3331234567"}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start">+92</InputAdornment>,
                                                }}
                                            />
                                        </ReactInputMask>
                                    )}
                                    control={control}
                                    name={'phone'}
                                    rules={{ required: { value: true, message: 'Cell number is required' } }}
                                />
                            </Grid>


                            {/* Company Name */}
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <TextField
                                            name={name}
                                            required
                                            fullWidth
                                            id={name}
                                            label="Company Name"
                                            error={Boolean(errors[name])}
                                            value={value}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            onChange={(event) => {
                                                onChange(event);
                                                setCompany(event.target.value);
                                            }}
                                        />
                                    )}
                                    control={control}
                                    name={'company'}
                                    rules={{ required: { value: true, message: 'Company name is required' } }}
                                />
                            </Grid>

                            {/* Role */}
                            <Grid item xs={12}>
                                <FormControl fullWidth>

                                    <Controller
                                        render={({ field: { name, value, onChange } }) => (
                                            <FormControl fullWidth>
                                                <InputLabel id="roleLabel">Role</InputLabel>
                                                <Select
                                                    labelId="roleLabel"
                                                    name={name}
                                                    id={name}
                                                    label="Role"
                                                    error={Boolean(errors[name])}
                                                    value={value}
                                                    onChange={(event) => {
                                                        onChange(event);
                                                        handleRoleChange(event);
                                                    }}
                                                >
                                                    {roles.map((m: any) => (
                                                        <MenuItem value={m}>{m}</MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText error={Boolean(errors[name])}>{errors[name] ? String(errors[name]?.message) : ""}</FormHelperText>
                                            </FormControl>

                                        )}
                                        control={control}
                                        name={'role'}
                                        rules={{ required: { value: true, message: 'Role is required' } }}
                                    />

                                </FormControl>
                            </Grid>

                            {/* Manager */}
                            {role === 'BOOKING_OFFICER' && <Grid item xs={12}>
                                <FormControl fullWidth>

                                    <InputLabel id="managerLabel">Manager</InputLabel>
                                    <Select
                                        labelId="managerLabel"
                                        id="manager"
                                        label="Manager"
                                        value={manager}
                                        onChange={(newValue) => {
                                            setManager(newValue.target.value);
                                        }}
                                    >
                                        <MenuItem value={"none"}><em>None</em></MenuItem>
                                        {managers.map((m: any) => (
                                            <MenuItem value={m.id}>{m.firstName} {m.lastName}</MenuItem>
                                        ))}

                                    </Select>

                                </FormControl>
                            </Grid>

                            }

                            {/* Designation */}
                            {role === 'SALES_MANAGER' && <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Controller
                                        render={({ field: { name, value, onChange } }) => (
                                            <FormControl fullWidth>
                                                <InputLabel id="designationLabel">Designation</InputLabel>
                                                <Select
                                                    labelId="designationLabel"
                                                    name={name}
                                                    id={name}
                                                    label="Designation"
                                                    error={Boolean(errors[name])}
                                                    value={value}
                                                    onChange={(event) => {
                                                        onChange(event);
                                                        setDesignation(event.target.value);
                                                    }}
                                                >
                                                    {designations.map((m: any) => (
                                                        <MenuItem value={m}>{m}</MenuItem>
                                                    ))}
                                                </Select>
                                                <FormHelperText error={Boolean(errors[name])}>{errors[name] ? String(errors[name]?.message) : ""}</FormHelperText>
                                            </FormControl>

                                        )}
                                        control={control}
                                        name={'designation'}
                                        rules={{ required: { value: true, message: 'Designation is required' } }}
                                    />

                                </FormControl>
                            </Grid>
                            }


                            {/* Address */}
                            <Grid item xs={12}>
                                <Controller
                                    render={({ field: { name, value, onChange } }) => (
                                        <TextField
                                            name={name}
                                            required
                                            fullWidth
                                            id={name}
                                            label="Address"
                                            error={Boolean(errors[name])}
                                            value={value}
                                            helperText={errors[name] ? String(errors[name]?.message) : ""}
                                            onChange={(event) => {
                                                onChange(event);
                                                setAddress(event.target.value);
                                            }}
                                        />
                                    )}
                                    control={control}
                                    name={'address'}
                                    rules={{ required: { value: true, message: 'Address is required' } }}
                                />
                            </Grid>

                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Update
                        </Button>

                    </Box>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </ThemeProvider>

    );


    return <Sidebar childComponent={newUserForm} index={3}  title={"Update User"} />;
}